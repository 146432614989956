<template>

    <input v-model="value" v-bind="inputAttrs"  :type="compoutedInputType" @focus="isFocused = true" @blur="isFocused = false"/>




</template>

<script>

    export default {
        props: {
            modelValue    : {},
            type: {},
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {
            return {
                isFocused: false,
                test: null
            };
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            compoutedInputType () {
                return this.isFocused ? 'date' : 'text'
            }
        },
        methods: {

        }

    }
</script>

<style scoped lang="scss">

</style>
