<template>

    <div class="unit-box uk-flex" :class="wrapperClass">
        <div class="minus uk-input unit-control uk-input uk-background-secondary uk-text-inverse"
             @click="decrement()">
            <span uk-icon="minus"></span>
        </div>
        <input v-model.number="value" :value="value" v-bind="inputAttrs" class="uk-input uk-input-units-field"/>
        <div class="plus uk-input unit-control uk-background-secondary uk-text-inverse"
             @click="increment()">
            <span uk-icon="plus"></span>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            minimum: {
                type: [Number, String],
                default: 1,
            },
            maximum: {
                type: [Number, String],
                default: 9999999999,
            },
            modelValue    : {},
            class : {
                type: String,
                default: ''
            },
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    if (value < parseInt(this.minimum)) {
                        this.$emit('update:modelValue', parseInt(this.minimum));
                        this.value = parseInt(this.minimum);
                        return;
                    }

                    if (value > parseInt(this.maximum)) {
                        this.$emit('update:modelValue', parseInt(this.maximum));
                        this.value = parseInt(this.maximum);
                        return;
                    }
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            wrapperClass() {
                let result = [''];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },
        },
        methods: {
            increment() {
                this.value = +parseInt(this.value) + 1;
            },
            decrement() {
                if (this.value > Number(this.minimum)) {
                    this.value = +parseInt(this.value) - 1;
                }

            }
        },
        watch: {

        }

    }
</script>

<style scoped lang="scss">
    .uk-input-units-field {
        $inputWidth: calc(var(--global-margin) * 2.5);
        flex: 0 0 $inputWidth;
        width: $inputWidth;
        min-width: $inputWidth;
        text-align: center;
    }

    .unit-control{
        transition: opacity 200ms;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
</style>
