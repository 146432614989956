let scriptCache = [];

let isScriptAdded = (url) => {
    return scriptCache.includes(url);
};

let addScript = async (url, options = {}) => {
    if ( isScriptAdded(url)) {
        return true;
    }
    
    return forceAddScript(url, options);
}

let forceAddScript = async (url, options = {type: false}) => {
    if (utilities.isSSR()) {
        return true;
    }
    
    return new Promise ((fulfil, reject) => {
        let script = document.createElement('script');
        script.src = url; // URL for the third-party library being loaded.
        if (options.type) {
            //script.type = options.type
        }
        script.id = 'added-script-' + utilities.getUniqueNumber(); // e.g., googleMaps or stripe
        document.body.appendChild(script);
    console.log(script);
        script.onload = () => {
            fulfil(true)
        };
    });
};

export default (props) => {
    return {addScript, forceAddScript}
}
