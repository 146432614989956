<template>
    <div class="title-separator"
    :class="dynamicClass">
        <div class="content uk-text-bold tr-font-m">
            <div class="line line--before"></div>
            <inline-block class="content-text"><slot>{{finalText}}</slot></inline-block>
            <div class="line line--after" ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: [String, Boolean],
            default: false,
        },
        theme: {
            type: [String],
            default: 'dark',
            validator (value) {
                return ['light', 'dark'].includes(value);
            }
        }
    },
    data: function () {
        return {};
    },
    computed: {
        finalText () {
            if (this.text) {
                return this.translateSafe(this.text);
            }
            return '';
        },
        dynamicClass () {
            let list = [];
            list.push('title-separator--' + this.theme);
            return list;
        }
    }

}
</script>

<style scoped lang="scss">
    .title-separator {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;

        .content {
            position: relative;
            z-index: 2;
        }
        .content-text {
            position: relative;
            display: inline-block;
            padding: 12px;
            background: var(--global-background);
            z-index: 2;
        }

        .line {
            content: ' ';
            position: relative;
            z-index: 1;
            flex: 1px;
            flex-grow: 900;
            height: 1px;
            background: var(--global-background);
            &--after {

            }

            &--before {

            }
        }

        .content {
            color: var(--global-background);
            background: transparent;
            flex: 100%;
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: 0;
            padding-right: 0;

            .content-text {
                flex: auto;
                background: transparent;
            }
        }
    }
</style>
