<template>
    <li class="action-button uk-flex uk-flex-column uk-flex-canter"
         @click="handleClick">
        <span class="action-button__icon" v-if="icon" :uk-icon="icon"></span>
        <inline-block class="action-button__text">
            <slot>
                {{finalText}}
            </slot>
        </inline-block>
    </li>
</template>

<script>
export default {
    props: {
        icon: {
            type: [String, Boolean],
            default: false,
        },
        text: {
            type: [String, Boolean],
            default: false,
        },
        navAction: {
            type: [Object, Boolean],
            default: false,
        },
    },
    data: function () {
        return {};
    },
    computed: {
        finalText() {
            if (typeof this.text === 'string') {
                return this.safeTranslate(this.text);
            }

            return '';

        },
    },
    methods: {
        handleClick(e) {
            if (this.navAction) {
                this.$router.push(this.navAction);
            }
        }
    }

}
</script>

<style scoped lang="scss">
    .action-button {
        //this is enabled by vue 3.2. All praise the mighty Evan You
        cursor: pointer;
        flex-grow: 3;
        padding: 12px;
        flex: 120px;
        text-align: center;
        color: var(--global-link-color);
        background: var(--global-background);
        font-size: var(--font-s);
        box-shadow: 0 0 24px -3px rgba(0, 0, 0, 0.12);
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;
        border-radius: var(--border-radius-small);
        transition: opacity 200ms ease, box-shadow 200ms ease;

        &__icon {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 20px;
            width: 40px;
            justify-self: center;
            margin: 0 auto;
            margin-bottom: 4px;


        }

        &:hover {
            opacity: 0.8;
            box-shadow: 0 0 24px -3px rgba(0, 0, 0, 0.32);
        }

    }
</style>
<style lang="scss">
//NONE SCOPED STYLE - required for uiKit icon
.action-button {
    svg {
        max-height: 20px;
    }
}
</style>
