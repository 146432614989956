<template>


  <select
    v-bind="inputAttrs"
    v-model="value"
  >
    <option v-if="hasNullOption"
            :disabled="finalNullOption.disabled"
            :value="finalNullOption.value"
            :selected="(finalNullOption.value === modelValue)"

    >{{ getLabelString(finalNullOption.label, true) }}
    </option>
    <option v-for="option in list"
            :value="option.value"
            :selected="(option.value === modelValue)">{{ getLabelString(option.label, true) }}
    </option>

  </select>
</template>

<script>
export default {
  props: {
    onInput: {
      //
    },
    onFocusout: {
      //
    },
    modelValue   : {},
    autoTranslate: {
      type   : Boolean,
      default: true,
    },
    translateList: {
      type   : Boolean,
      default: true,
    },
    list         : {
      type   : Array,
      default: []
    },
    nullOption   : {
      type   : [Object, Boolean],
      default: true,
    }
  },

  data    : function () {
    return {

    }
  },
  emits   : ['update:modelValue'],

  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },

    inputAttrs () {
      let attrs = { ...this.$attrs }
      let deleteList = [
      ///  'onInput',
      //  'onFocusout',
      ];
      deleteList.map(key => {
        console.log('removed', key, attrs[key]);
        delete attrs[key];
      })

      return attrs
    },
    hasNullOption () {
      return this.nullOption !== false
    },
    hasSpecificNullOption () {
      return typeof this.nullOption === 'object' && this.nullOption !== null
    },
    finalNullOption () {
      let target = {}, disabled

      if (this.hasSpecificNullOption) {
        target = this.nullOption
      }

      if (target.disabled) {
        disabled = true
      } else {
        disabled = target.disabled
      }

      return {
        value: target.value || null,
        label: target.label || 'core.selectPlaceholder',
        disabled,
      }

    }
  },
  methods : {
    getLabelString (label, isforList) {
      return label
      if (isforList) {
        return this.translateList ? this.translate(label, {}, true) : label
      } else {
        return this.autoTranslate ? this.safeTranslate(label) : label
      }

    }
  },

}
</script>

<style scoped lang="scss">

</style>
