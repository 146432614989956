<template>


        <button-group
                :class="wrapperClass"
                class="uk-flex-wrap">

            <form-button
                    v-for="option in list"
                    :key="option"
                    :icon="option.icon ? option.icon : false"
                    :type="option.value == value ? 'primary' : 'secondary'"
                         @click="value = option.value"
            >{{translateIfNeeded(option.label)}}</form-button>

            <input v-model="value" type="text" v-bind="inputAttrs"/>
        </button-group>



</template>

<script>
    export default {
        props: {
            modelValue    : {},
            autoTranslate: {
                type: Boolean,
                default: true
            },
            list: {
                type: Array,
                default: []
            },
            class : {
                type: String,
                default: ''
            },
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
            inputAttrs() {
              let attrs = {...this.$attrs};
              delete attrs.type;
              delete attrs.list;
              attrs.type = 'hidden';
              return attrs;
            },
            controlsClass () {
                let classArray = [];


                return classArray;
            },
            wrapperClass() {
                let result = [''];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },

        },
        methods: {
          translateIfNeeded(str) {
              if (this.autoTranslate) {
                  return this.translate(str);
              } else {
                  return str;
              }
          }
        },
        watch: {
            modelValue: {
                handler: function (oldValue, newValue) {
                   //

                },
                immediate: true
            }
        }


    }
</script>

<style scoped lang="scss">



</style>
