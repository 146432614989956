<template>

    <div class="uk-flex uk-flex-middle select2-item-icon" :class="{rtl: isLanguageRtl()}">
            <span uk-icon="check"
                  class="select2-item-icon__icon uk-flex uk-animation-slide-bottom-small"
                  v-show="selected"></span>

                <span :uk-icon="'icon:'+finalIcon+';ratio: 1.5'"
                      class="select2-item-icon__icon  select-item-icon__icon--not-selected uk-flex uk-animation-slide-left-small"
                      :class="{'uk-animation-slide-left-small' : !isLanguageRtl(), 'uk-animation-slide-right-small' : isLanguageRtl()}"
                      v-show=" ! selected">

            </span>

            <span>
              {{getLabelString(item.label)}}
            </span>
        </div>


</template>

<script>
export default {
  name: 'iconItem',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: {}
    },

    autoTranslate: {
      type: Boolean,
      default: true,
    },
    defaultIcon: {
      type: String,
      default: 'triangle-right',
    }
  },
  data: function () {
    return {};
  },
  computed: {
      directionAgnosticDefaultIcon () {
          if (this.defaultIcon != 'triangle-right') {
              return this.defaultIcon;
          }
          return this.isLanguageRtl ? 'triangle-left' : 'triangle-right';
      },
      finalIcon () {
      // use our icon, if provided
      if ( this.item.icon &&  this.item.icon !== '') {
        return this.item.icon;
      }

      // use default icon if able
      if(this.defaultIcon !== '') {
        return this.directionAgnosticDefaultIcon;
      }

      // we have no icon. use hard coded default
      return this.isLanguageRtl ? 'triangle-left' : 'triangle-right';
    }
  },
  methods: {
    getLabelString (label) {
      return this.autoTranslate ? this.safeTranslate(label) : label;
    }
  }

}
</script>

<style scoped lang="scss">
.select2-item-icon {
    padding: calc(var(--global-margin) * 0.35) calc(var(--global-margin) * 0);
    margin-bottom: calc(var(--global-margin) * 0.5);
    background: var(--global-muted-background);
    border-left: 4px solid var(--global-muted-background);
    transition: padding 150ms ease-out 0ms, border-color 150ms ease-out;

    &.highlight {
        padding-inline-start: calc(var(--global-margin) * 0.3);
        border-left-color: var(--global-primary-background);

        .select2-item-icon__icon {
            margin-inline-end: calc(var(--global-margin) * 0.35);
        }
    }

    &.rtl {
        border-left: none;
        border-right: 4px solid var(--global-muted-background);

        &.highlight {
            border-right-color: var(--global-primary-background);
        }
    }


    &:last-of-type {
  //  border-bottom: none;
  }
}
.select2-item-icon__icon {
  display: flex;
  margin-inline-end: calc(var(--global-margin) * 0.2);
  min-height: calc(var(--global-margin) * 1.5);
    transition: margin 150ms ease-out 90ms;


}


</style>
