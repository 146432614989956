<template>

    <div class="default-layout">

        <keep-alive>
            <talron-header keep-alive :class="{'uk-visible@m' :   ! showHeader}"></talron-header>
        </keep-alive>

        <main class="main-content" :class="mainContentDynamicClass">
            <div class="main-content-bg" :class="mainContentDynamicClass">
            </div>
              <div class="main-content-content">
                  <slot></slot>
              </div>
        </main>

        <!-- todo: consider: move to footer component -->
        <keep-alive>
            <talron-footer v-if="showFooter"></talron-footer>
        </keep-alive>

    </div>



</template>

<script>
import TalronHeader from '@/client/applications/talron/components/talron/Header.vue';
import TalronFooter from '@/client/applications/talron/components/talron/Footer.vue';
import { mapGetters } from 'vuex'
    export default {
        props: {
            /*
            backgroundImage: {
                type: String,
                default: 'page-bg-texture.png',
            }
            */
        },
        components: {
            TalronHeader, TalronFooter
        },
        data () {
            return {
               // showHeader: this.$store.getters['talron/showHeader'],
            }
        },
        computed: {
            ...mapGetters({
                  'showHeader' : 'talron/showHeader',
                  'showFooter' : 'talron/showFooter',
                  'appActionState' :   'talron/appActionState',
              }),
            showDevLogout() {
                if ( ! this.$store.getters['talron/hasTalronUser']) {
                    return false;
                }

                if (process.env.NODE_ENV === "development") {
                    return true
                }

                return false;

            },
            mainContentDynamicClass () {
                return 'main-content--' + this.appActionState;
            },
            contentBackgroundUrlCss () {
                 let seed = 'images/backgrounds/background-icons-1.png';
                 return "url('"+this.getDynamicAssetUrl(seed)+"')";
               // let seed = 'images/backgrounds/main-content-'+this.appActionState+'.svg';
               // return "url('"+this.getDynamicAssetUrl(seed)+"')";
            },
            contentBackgroundUrlCssDesktop () {
                let seed = 'images/backgrounds/main-content-default-desktop.svg';
                return "url('"+this.getDynamicAssetUrl(seed)+"')";
            },

        },
        methods: {
            async talronLogout() {
                await this.$store.dispatch('talron/logoutTalronUser');
                this.$router.push(config.user.guestLoginRedirect);
            }
        },
        mounted () {
        /*
            setTimeout(() => {
                console.log('success');
                this.$store.commit('talron/showHeader', false);
            }, 2000);
*/
/*
            setTimeout(() => {
                console.log('success');
                this.$store.commit('talron/appActionState', 'success');
            }, 1000);

            setTimeout(() => {
                console.log('default');
                this.$store.commit('talron/appActionState', 'default');
            }, 2000);

            setTimeout(() => {
                console.log('error');
                this.$store.commit('talron/appActionState', 'error');
            }, 3000);

            setTimeout(() => {
                console.log('reset');
                this.$store.commit('talron/resetAppActionState');
            }, 4000);
*/

        }
    }
</script>

<style lang="scss" scoped >

.default-layout {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: stretch;
    justify-content: space-between;
}



.main-content {
    position: relative;
    min-height: 100px;
    width: 100%;
    background-repeat: repeat;
    background-size: auto;
   // background-image: v-bind(contentBackgroundUrlCss);
    flex: 100%;
    z-index: 2;
    transition: all 300ms ease;

    @media (max-width: 1200px) {
        background-image: v-bind(contentBackgroundUrlCss);
    }

    .main-content-bg {
        position: absolute;
        z-index: 0;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: v-bind(contentBackgroundUrlCss);
        opacity: 0.3;

        @media (min-width: 1200px) {
            display: none
        }
    }
    .main-content-content {
        position: relative;
        z-index: 2;
    }

    &--success {
        //v-bind(contentBackgroundUrlCss)
        background-image:  linear-gradient(45deg, #5DA036 0%, #CFEC86 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #5DA036 0%, #CFEC86 100%);
        }
    }

    &--error {
        background-image: linear-gradient(45deg, #FF7878 0%, #FFC3C3 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #FF7878 0%, #FFC3C3 100%);
        }
    }

    &--default {
        background-image: linear-gradient(45deg, #01548B 0%, var(--global-primary-background) 100%);
        @media (min-width: 1200px) {
            background-image:  v-bind(contentBackgroundUrlCss) linear-gradient(45deg, #01548B 0%, var(--global-primary-background) 100%);
        }
    }

    // desktop doesnt change background, at all
    &--success, &--error, &--default {
        @media (min-width: 1200px) {
            background-repeat: no-repeat;
            background-size: cover;
            background-image: v-bind(contentBackgroundUrlCssDesktop);
        }
    }


}






</style>
