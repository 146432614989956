<template>
    <div class="boxy-layout">
        <div class="boxy__outer">
            <div class="background-image" :style="boxyOuterStyle"></div>
            <div class="shader"></div>

            <div class="boxy__inner  uk-background-default " uk-overflow-auto>

                <div class="boxy__card uk-card uk-card-default uk-card-body ">
                    <slot></slot>
                </div>




            </div>

<!--

                <div class="uk-card-title">
                    <slot name="title">Test</slot>
                </div>
                <div class="uk-card-body">
                    <slot></slot>
                </div>
-->

        </div>
    </div>

</template>

<script>

    export default {
        props: {
            'defaultComponent' : String,
            backgroundImage: {
                type: String,
                default: 'core/images/user/login/boxy_background.jpg'
            }
        },
        data: function () {
            return {

            };
        },
        computed: {
            boxyOuterStyle () {
                let url = utilities.requireAsset(this.backgroundImage);
                return {'backgroundImage' : "url('"+url+"')"};
            }
        }

    }
</script>


<style lang="scss">
    .boxy-layout {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        max-height: 100vh;
        overflow: hidden;
    }

    .shader, .background-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

    }

    .background-image {
        filter: blur(2px);
        -webkit-filter: blur(2px);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .shader {
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 2;
    }

    .boxy__outer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 100%;
        overflow: hidden;
        z-index: 4;
    }

    .boxy__inner {
        position: relative;
        z-index: 4;
        max-height: 90vh;
        box-shadow: 0 20px 35px rgba(0, 0, 0, 0.36);
    }

    .boxy__card {
        @media (max-width: 640px) {
            padding: 20px 15px;
        }
    }
</style>
