<template>
    <div>
        <block class="uk-form-label" v-if="label">
            {{translate(label)}}
        </block>
        <div class="talron-flex-fieldset-fields uk-flex uk-flex-gap-m">
            <slot></slot>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: [String, Boolean],
            default: false
        }
    },
    data: function () {
        return {};
    }

}
</script>

<style scoped lang="scss">


</style>

<style lang="scss">
.talron-flex-fieldset-fields .input-wrapper {
    flex: 100%;
}
</style>
