<template>

    <div :class="wrapperClass">
        <div class="uk-flex option-list">

            <div class="option-item"
                 v-for="item in list"
                 :class="{'selected': item.value == value}"
                 @click="this.value = item.value">
                <div class="selected-icon" v-if="item.value == value">
                    <div uk-icon="icon:check; ratio: 0.85"></div>
                </div>
                <div class="inner">

                    {{autoTranslate ? translate(item.label) : item.label}}
                </div>
            </div>
        </div>

        <input v-model="value" type="text" v-bind="inputAttrs"/>
    </div>

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {
                type: Array,
                default: []
            },
            class : {
                type: String,
                default: ''
            },
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
            inputAttrs() {
              let attrs = {...this.$attrs};
              delete attrs.type;
              delete attrs.list;
              attrs.type = 'hidden';
              return attrs;
            },
            controlsClass () {
                let classArray = [];


                return classArray;
            },
            wrapperClass() {
                let result = ['radio-labels-wrapper'];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },

        },
        watch: {
            modelValue: {
                handler: function (oldValue, newValue) {
                   //

                },
                immediate: true
            }
        }


    }
</script>

<style scoped lang="scss">

    // specific selector to override UIkit
    .color-select-wrapper.uk-form-large:not(textarea):not([multiple]):not([size]) {
        padding-left: 0;
        padding-right: 0;
    }

    .option-list {
        box-sizing: border-box;



        * {
            box-sizing: border-box;
        }

        .option-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-inline-end: calc(var(--global-margin) * 0.6);
            height:  auto;
            width:  auto;
            border: 2px solid transparent;
            border-radius: 0;
            padding:calc(var(--global-margin) * 0.1)  calc(var(--global-margin) * 0.6);
            cursor: pointer;
            transition: border-color 150ms, box-shadow 150ms;
            border-color: var(--global-muted-color);


            &.selected {
                border-color: var(--global-primary-background);

                .inner {
                    color: var(--global-primary-background);
                }
            }

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 0;
                color: var(--global-muted-color);
                font-weight: 500;
                border-radius: 4px;


            }

            .selected-icon {
                position: absolute;
                display: flex;
                top: -50%;
                right: calc(var(--global-margin) * -0.5);
                background-color: var(--global-primary-background);
                color: var(--global-inverse-color);
                padding: 1px;
                border-radius: 50%;
                font-weight: bold;
                z-index:2;

            }
            &:hover {
                box-shadow: 0 0 8px 0px rgba(0,0,0,0.3) inset;
            }

            &.selected:hover {
                box-shadow: none;
            }

        }
    }


</style>
