<template>
    <header class="talron-header"
            :class="{'uk-visible@m' :   ! showHeader}">
        <button class="talron-menu-bars"
              uk-icon="talron-menu-bars"
              :aria-expanded="navOpened"
              style="appearance: none;"
              tabindex="0"
              role="button"
                :aria-label="translate('talron.menu.ariaMenuButton')"
              v-show=" ! navOpened"
              @click="openNav()"></button>

        <img class="logo" :src="getDynamicAssetUrl('images/logos/logo.png')" :alt="translate('talron.alt.logo')" />

        <div class="main-nav" v-show="navOpened">
            <div class="nav-overlay uk-animation-fade-small" v-show="navOpened"></div>
            <transition
                name="custom-classes"
                enter-active-class="uk-animation-slide-right-small"
                leave-active-class="uk-animation-slide-left-small"
            >
                <div class="main-nav-inner" v-show="navOpened">
                    <span class="menu-close"
                          aria-expanded="true"
                          uk-icon="close"
                          @click="navOpened = false"></span>

                    <div class="menu-logo uk-flex uk-flex-center uk-flex-middle uk-margin-large-top uk-margin-large-bottom">
                        <img class="menu-logo-image" :src="getDynamicAssetUrl('images/logos/logo.png')" alt="">
                    </div>

                    <ul class="nav">
                        <li>
                            <router-link
                                :to="{name: 'index'}"
                                @click="closeNav()"
                                ref="firstMenuLink"
                                v-if="hasUser">
                                <span uk-icon="menu-barcode-scan"></span>
                                <span>{{translate('talron.menu.scan')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{name: 'index'}"
                                @click="closeNav()"
                                ref="firstMenuLink"
                               v-if="! hasUser">
                                <span uk-icon="menu-barcode-scan"></span>
                                <span>{{translate('talron.menu.scan')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{name: 'qna'}"
                                @click="closeNav()"
                            >
                                <span uk-icon="talron-menu-help"></span>
                                <span>{{translate('talron.menu.qna')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </router-link>
                        </li>
                        <li>
                            <a :href="navData.serviceStationslink"
                               @click="closeNav()"
                               target="_blank">
                                <span uk-icon="talron-menu-stations"></span>
                                <span>{{translate('talron.menu.stations')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </a>
                        </li>
                        <li>
                            <a :href="navData.warrantyFileLink"
                               @click="closeNav()"
                               target="_blank">
                                <span uk-icon="menu-warranty-document"></span>
                                <span>{{translate('talron.menu.warrantyDocument')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </a>
                        </li>
                        <li>
                            <a :href="navData.heatSignatureExplenationLink"
                               @click="closeNav()"
                               target="_blank">
                                <span uk-icon="menu-warranty-explain"></span>
                                <span>{{translate('talron.menu.heatSignatureExplain')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </a>
                        </li>
                        <li>
                            <router-link
                                :to="{name: 'contact'}"
                                @click="closeNav()"
                            >
                                <span uk-icon="talron-menu-contact"></span>
                                <span>{{translate('talron.menu.contact')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </router-link>
                        </li>
                        <li v-show="hasUser">
                            <a href="#"
                               @click="closeNav()"
                               @click.prevent="talronLogout">
                                <span uk-icon="menu-logout"></span>
                                <span>{{translate('talron.menu.logout')}}</span>
                            </a>
                        </li>

                        <li v-show=" ! hasUser">

                            <router-link
                                :to="{name: 'login'}"
                                @click="closeNav()"
                            >
                                <span uk-icon="menu-login"></span>
                                <span>{{translate('talron.menu.login')}}</span>
                                <span class="icon-end" uk-icon="chevron-left"></span>
                            </router-link>
                        </li>

                    </ul>

                </div>
            </transition>

        </div>

    </header>

</template>

<script>
import {mapGetters} from "vuex";

export default {
    data: function () {
        return {
            navOpened: false,
        };
    },
    computed: {
        ...mapGetters({
                          'hasUser' :'talron/hasUser',
                          'showHeader'    : 'talron/showHeader',
                          'showFooter'    : 'talron/showFooter',
                          'appActionState': 'talron/appActionState',
                          'navData' : 'talron/sideNav',
                      }),
        showDevLogout() {
            if ( ! this.$store.getters['talron/hasTalronUser']) {
                return false;
            }

            if (process.env.NODE_ENV === "development") {
                return true
            }

            return false;

        },
        routeFullPath() {
            return this.$route.fullPath;
        }
    },
    methods: {
        openNav () {
            this.navOpened = true;
            setTimeout(() => {
                this.$refs.firstMenuLink.$el.focus();
            }, 10);
        },
        closeNav () {
            this.navOpened = false;
        },
        executeNavigation(arg) {
            this.$router.push(arg);
            this.closeNav();
        },
        async talronLogout() {
            await this.$store.dispatch('talron/logoutTalronUser');
            this.$router.push(config.user.guestLoginRedirect);
            this.closeNav();
        },
        windowKeypressHandler(e) {
            if ( ! e.keyCode) {
                return;
            }

            if (e.keyCode === 27) {
                this.closeNav();
            }
        }
    },
    watch: {
        route (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.closeNav();
            }
        }
    },
    mounted() {
        if (window) {
            window.addEventListener('keyup', this.windowKeypressHandler)
        }
    },
    beforeUnMount() {
        if (window) {
            window.removeEventListener('keyup', this.windowKeypressHandler)
        }
    },

}
</script>

<style scoped lang="scss">
    .talron-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white;
    padding: 10px 20px;
    z-index: 3;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);

    .talron-menu-bars {
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 20px; // old iphone support
        inset-inline-start: 20px;
        width: 17px;
        height: 12px;
        margin-top: -6px;
        line-height:0;
        cursor: pointer;
    }

    svg {
        width: 17px;
        height: 12px;
    }

    .logo {
        height: 40px;
        width: auto;
    }
}

    .main-nav {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        inset-inline-start: 0;
        z-index: 50;



        .nav-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            background: rgba(0,0,0,0.8);
        }


        .main-nav-inner {
            position: relative;
            height: 100vh;
            width: clamp(300px, 50vw, 500px);
            top: 0;
            inset-inline-start: 0;
            z-index: 2;
            border-radius: var(--border-radius-medium) 0 0 0 ;
            background: var(--global-background);
            padding: var(--global-margin) 0;
        }

        .menu-close {
            position: absolute;
            top: var(--global-margin);
            inset-inline-start: var(--global-margin);
            z-index: 2;
            cursor: pointer;
        }

        .menu-logo {

            .menu-logo-image {
                width: 80px;
                height: auto;
            }
        }

        .nav {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        li {
            display: block;

            &:first-of-type {
                a {
                    border-top: 1px solid  #ddd;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: var(--global-margin);
            text-decoration: none;
            color: var(--global-color);
            border-bottom: 1px solid  #ddd;
            padding: var(--global-margin);
            font-size: var(--font-m);
            font-weight: 600;

            &.uk-active {
                background: var(--global-muted-background);
            }

            .icon-end {
                margin-inline-start: auto;
                margin-inline-end: 0;
            }


        }
    }

    ul a {

    }

</style>
