<template>

    <div :class="wrapperClass">


        <div class="option-list uk-flex uk-flex-column">
            <div class="option uk-flex "
                 :class="{selected : value == option.value}"
                 v-for="option in list" :key="option"
                 @click="value = option.value"
            >
                <div class="uk-flex uk-flex-middle">
                    <div class="radio-dummy"></div>
                </div>
                <div class="uk-flex uk-width-expand uk-flex uk-flex-column">
                    <strong>{{option.label}}</strong>
                    <br>
                    <span >{{option.description}}</span>
                </div>


            </div>
        </div>

        <input v-model="value" type="text" v-bind="inputAttrs"/>
    </div>

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {
                type: Array,
                default: []
            },
            class : {
                type: String,
                default: ''
            },
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
            inputAttrs() {
              let attrs = {...this.$attrs};
              delete attrs.type;
              delete attrs.list;
              attrs.type = 'hidden';
              return attrs;
            },
            controlsClass () {
                let classArray = [];


                return classArray;
            },
            wrapperClass() {
                let result = ['radio-labels-wrapper'];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },

        },
        watch: {
            modelValue: {
                handler: function (oldValue, newValue) {
                   //

                },
                immediate: true
            }
        }


    }
</script>

<style scoped lang="scss">

    .option-list {

        .option {
            margin-bottom: calc(var(--global-margin) * 0.4);
            padding-top:    calc(var(--global-margin) * 0.4);
            padding-right:  calc(var(--global-margin) * 0.7);
            padding-bottom: calc(var(--global-margin) * 0.4);
            padding-left:   calc(var(--global-margin) * 0.7 - 4px);

            cursor: pointer;

            border-left: solid 4px transparent;
            box-shadow:  0 0 6px 1px rgba(0,0,0,0.2);
            transition: box-shadow 200ms, border 200ms;

            &:hover {
                box-shadow:  0 0 12px 3px rgba(0,0,0,0.3);
            }

            [dir=rtl] & {
                border-left: none;
                border-right: solid 4px transparent;
            }
        }

        .radio-dummy {
            position: relative;
            border: 2px solid var(--global-muted-color);
            height: calc(var(--global-margin) * 1.1);
            width: calc(var(--global-margin) * 1.1);
            border-radius: 50%;
            margin-right:  calc(var(--global-margin));
            transition: border 200ms, background 200ms;

            [dir=rtl] & {
                margin-right:  0;
                margin-left:  calc(var(--global-margin));
            }

            &:after {
                content: ' ';
                position: absolute;
                height: calc(var(--global-margin) * 0.50);
                width: calc(var(--global-margin) * 0.50);
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background: var(--global-inverse-color);
                border-radius: 50%;
                opacity: 0;
                transition: opacity 200ms;

            }

        }

        .selected {
            border-color: var(--global-primary-background);

            .radio-dummy {
                background: var(--global-primary-background);
                border-color: var(--global-primary-background);

                &:after {
                    opacity: 1;

                }
            }
        }
    }


</style>
