<template>
    <div :class="alertClass"  :uk-alert="alertConfig" ref="alertWrapper">
        <a v-if="showClose"
           class="uk-alert-close"
           @click="$emit('alert:closing')"
           uk-close
        ></a>

        <slot></slot>
    </div>
</template>

<script>


    export default {
        props: {
            /**
             * How should this animate
             * todo: this is not working fully
             * @values [animation-class-name], true, false
             **/
            animation : {
                type: [String, Boolean],
                default: true,
            },
            /**
             * Type of alert
             * @values primary, success, warning, danger
             **/
            type: {
                type: String,
                default: 'primary'
            },
            /**
             * Close icon type.
             * If default, will close itselve. Can have event firing instead (self clsoe fires event also)
             * If none, no close icon will show
             * TODO: closeType:none is duplicate of selfClose...
             * @values default, event, none
             */
            closeType: {
                type : String,
                default: 'default'
            }
    },
        data: function () {
        return {};
        },
        computed : {
            alertClass () {
                return ['uk-alert', 'uk-alert-'+this.type]
            },
            alertConfig () {
                // todo: this is not fully implemented
                let result = 'animation: true;';

                // assign the close element to something that doesnt exist if we shoudl not self clsoe
                if ( ! this.selfClose) {
                    result = result + ' sel-close: .non-existent-class-element'
                }

                return ''
            },
            showClose () {
                return this.closeType !== 'none';
            },
            closeAsEvent () {
                return this.closeType === 'event';
            },
            selfClose () {
                return this.closeType === 'default';
            }
        },
        methods: {
            close () {
                this.$s.ui.alert(this.$refs.alertWrapper).close();
            }
        },
        created () {

        }
    }
</script>

<style scoped lang="scss">

</style>
