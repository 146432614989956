<template>
    <fieldset class="base-fieldset">
        <h3 class="fieldset-title uk-flex uk-flex-middle uk-flex-gap-s " v-if="title">
            <i :uk-icon="'icon:'+icon+';ratio: 1.2'" v-if="icon"></i>
            {{translateSafe(title)}}
        </h3>
       <div class="content">
           <slot></slot>
       </div>
    </fieldset>
</template>

<script>
export default {
    props: {
        title: {
            type: [String, Boolean],
            default: false,
        },
        icon: {
            type: [String, Boolean],
            default: false,
        }
    },
    data: function () {
        return {};
    }

}
</script>

<style scoped lang="scss">
    $small-padding: calc(var(--global-margin) * 0.3);
    $base-padding: calc(var(--global-margin) * 0.6);
    .base-fieldset {
        border: none;
        padding: 0;
        margin-bottom: calc(var(--global-margin) * 1.5);

        .content {
            padding: $base-padding;
            border: 1px solid var(--global-primary-background);
            background: var(--global-muted-background);
            height: 100%;
            align-self: stretch;


        }
        .fieldset-title {
            flex: 100%;
            padding:   $small-padding;
            background-color: var(--global-primary-background);
            color: var(--global-inverse-color);
        }
        h3 {
            font-size: calc(var(--global-font-size) * 1.5);
            margin-bottom: 0;
         //   color: var(--global-primary-background);
        }
    }
</style>
