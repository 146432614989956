<template>
    <slot></slot>
</template>

<script>
export default {
    data: function () {
        return {};
    }

}
</script>

<style scoped lang="scss">

</style>
