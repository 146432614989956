<template>
    <div class="talron-progress-bar">
        <div class="label-block tr-font-m" v-if="hasLabel">
            {{finalLabel}}
        </div>
        <div class="progress-bar-block">
            <div class="bar">
                <div class="progress" :style="{'max-width': '100%',width: this.percentProgress+'%'}"></div>
            </div>
        </div>
        <div class="progress-bar-labels uk-flex uk-flex-between uk-text-small" v-if="hasProgressLabels">
            <div class="label-start">
                <span>{{finalStartLabel}}</span>
            </div>
            <div class="label-end">
                <span>{{finalEndLabel}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: [String, Boolean],
            default: false,
        },
        startValue: {
          type: [Number],
          default: 0
        },
        currentValue: {
            type: [Number],
            default: 0
        },
        /**
         * End value (total bar size, numeric). Must not be 0 or the gods of math
         * will unleash a tide of darkness to cover the entire cosmos
         * and then, I will be sad.
         */
        endValue: {
            type: [Number],
            default: 0,
            validator (val) {
                return val != 0;// promiscuous validation to avoid anything similar to 0
            }
        },
        startLabel : {
            type: [Boolean, String, Number],
            default: false
        },
        endLabel : {
            type: [Boolean, String, Number],
            default: false
        },
        translateValueLabels: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {};
    },
    computed: {
        hasLabel () {
            return typeof this.label === 'string';
        },
        finalLabel () {
            return this.safeTranslate(this.label);
        },
        hasProgressLabels() {
            return this.startLabel && this.endLabel;
        },
        finalStartLabel() {
            if (this.translateValueLabels) {
                return this.safeTranslate(this.startLabel);
            }
            return this.startLabel;
        },
        finalEndLabel() {
            if (this.translateValueLabels) {
                return this.safeTranslate(this.endLabel);
            }
            return this.endLabel;
        },
        percentProgress () {

            let range = this.endValue - this.startValue;
            let current = this.currentValue - this.startValue
            try {
                return (current/range) * 100
            } catch (e) {
                return 0;
            }

        }
    }

}
</script>

<style scoped lang="scss">
    .talron-progress-bar {

    }

    .label-block {
        margin-bottom: 7px;
    }

    .progress-bar-block {
        width: 100%;
    }

    .bar {
        position: relative;
        width: 100%;
        border-radius: 8px;
        background-color: #F1F1F1;
        height: 6px;
    }

    .progress {
        display: block;
        height: 100%;
        border-radius: 8px;

        background:  linear-gradient(to right, #856514 -76%, #A98019 113%);
        transition: width 200ms ease-in-out;
    }



    .progress-bar-labels {
        margin-top: 4px;
    }
</style>
