<template>

    <div class="welcome">
        <div class="wrapper">
            <div class="top-content">
                <div class="main-block uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                    <slot name="topBlock"></slot>
                </div>

            </div>
            <div class="bottom-content">
                    <div class="info-block uk-text-inverse info-block-mobile uk-hidden@l">
                        <div class="info-block-inner">
                            <slot name="bottomBlock"></slot>
                        </div>

                    </div>
                    <div class="info-block  info-block-desktop uk-visible@l">
                        <div class="info-block-inner">
                            <slot name="bottomBlock"></slot>
                        </div>

                    </div>

            </div>
        </div>

    </div>



</template>
<script setup>
import { mapGetters } from 'vuex'
</script>
<script>

    export default {
        props: {
            topBlockBackgroundUrlDesktop: {
                type: [String],
                default: '',
            },
            topBlockBackgroundUrlMobile: {
                type: [String],
                default: '',
            },

            topBlockBackgroundDesktop: {
                type: [String],
                default: '',
            },
            topBlockColorMobile: {
                type: [String],
                default: '',
            },

            bottomBlockBackgroundUrlDesktop: {
                type: [String],
                default: '',
            },
            bottomBlockBackgroundUrlMobile: {
                type: [String],
                default: '',
            },

            bottomBlockColorDesktop: {
                type: [String],
                default: '',
            },

            bottomBlockColorMobile: {
                type: [String],
                default: '',
            },
        },
        components: {

        },
        data () {
            return {
               // showHeader: this.$store.getters['talron/showHeader'],
            }
        },
        computed: {
            topBlockBackgroundImageUrlCssMobile() {
                return `url('${this.topBlockBackgroundUrlMobile}')`;
            },
            topBlockBackgroundImageUrlCssDesktop() {
                return `url('${this.topBlockBackgroundUrlDesktop}')`;
            },
            bottomBlockBackgroundImageUrlCssMobile() {
                return `url('${this. bottoBlockBackgroundUrlMobile}')`;
            },
            bottomBlockBackgroundImageUrlCssDesktop() {
                return `url('${this.bottomBlockBackgroundUrlDesktop}')`;
            },
            finaltopBlockBackgroundDesktop () {
                return this.topBlockBackgroundDesktop ?  this.topBlockBackgroundDesktop : 'rgba(255, 255, 255, 0.9)';
            },
            finalTopBlockColorMobile () {
                return this.topBlockColorMobile ?  this.topBlockColorMobile : 'rgba(255, 255, 255, 0.9)';
            },
            finalBottomBlockColorDesktop () {
                return this.bottomBlockColorDesktop ?  this.bottomBlockColorDesktop : 'var(--global-background)';
            },
            finalBottomBlockColorMobile () {
                return this.bottomBlockColorMobile ?  this.bottomBlockColorMobile : 'transparent';
            },
        },
        mounted () {


        }
    }
</script>

<style lang="scss" scoped >

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 20px;

    @media (min-width: 1200px) {
        padding: 80px;
    }
}

.main-block {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: v-bind('finalTopBlockColorMobile');
    background-image: v-bind('topBlockBackgroundImageUrlCssMobile');
    padding: 30px 20px 20px 20px;
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
    z-index: 2;
    @media (min-width: 1200px) {
        border-radius: var(--border-radius-large);
        background-color: v-bind('finaltopBlockBackgroundDesktop');
        background-image: v-bind('topBlockBackgroundImageUrlCssDesktop');
        padding: 30px 20px 160px 20px;
    }
}

.info-block-inner {
    max-width : 450px;
    margin    : 0 auto;

    @media (min-width : 1200px) {
        max-width : 700px;
    }
}


.info-block-mobile {
    padding: 40px 10px;
    background-color: v-bind('finalBottomBlockColorMobile');
    background-image: v-bind('bottomBlockBackgroundImageUrlCssMobile');

    position: relative;


}

.info-block-desktop {
    position: relative;
   // background: var(--global-background);
    padding: 32px 60px 60px 60px;
    border-radius: 100px 100px var(--border-radius-large) var(--border-radius-large);
    box-shadow: 0 -30px 50px -7px rgba(0, 0, 0, 0.07);
    margin-top: -120px;
    z-index: 1;
    background-color: v-bind('finalBottomBlockColorDesktop');
    background-image: v-bind('bottomBlockBackgroundImageUrlCssDesktop');
}

.top-content {
    @media screen and (min-width: 1200px) {
        position: relative;
        z-index: 1;
    }

}

.bottom-content {
    @media screen and (min-width: 1200px) {
        position: relative;
        z-index: 2;
    }
}
</style>
