<template>
    <textarea v-bind="inputAttrs"
            v-model="value"
    >{{value}}</textarea>
    <span class="character-counter" v-if="hasCharacterCounter">
        {{translate('core.form.characterLimit', {current: currentCharecters, max: maxCharecters})}}
    </span>
</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {}, // prevent inheritence of list="" on element
            characterCounter: {
                type: [Object, Boolean],
                default: false,
            }
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {

            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            hasCharacterCounter() {
                return typeof this.characterCounter === 'object' && this.characterCounter !== null;
            },
            maxCharecters () {
                if ( ! this.characterCounter) {
                    return 0;
                }

                return this.characterCounter.max || this.translate('core.unlimited');
            },
            currentCharecters () {
                return this.modelValue.length;
            }
        },
    }
</script>

<style scoped lang="scss">

    .character-counter {
        display: block;
    }
</style>
