<template>

    <div class="file-button-input" uk-form-custom>
        <p class="file-input-explain" >{{explainText}}</p>
        <input v-on:change="changeHandler" type="file" :class="finalClass" v-bind="inputAttrs" />
        <button class="uk-button uk-button-default" type="button" tabindex="-1">{{translate('core.selectFiles')}}</button>
    </div>

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {}, // prevent inheritence of list="" on element
            type: {}, // prevent inheritence of the type attribute
            /**
             * Explenation text about the files
             */
            explain: {
                type: [String, Boolean],
                default: false
            }
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                delete attrs.class;
                return attrs;
            },
            finalClass () {
                let result = this.$attrs.class;

                if (typeof result === 'string') {
                    result = result.replace('uk-input', '');
                }

                return result;
            },
            explainText () {
                return this.explain ? this.translate(this.explain) : '';
            }
        },
        methods: {
            // TODO: handle files in our form
            changeHandler(e) {
                console.log(e.target.files);
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
