<template>
  <footer class="talron-footer">
    <div class="footer-inner uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-container uk-margin-auto">
      <div class="footer-menu">

        <ul>
          <li>
            <a @click.prevent="$router.push({name: 'contact'});" href="#">
              {{ translate('talron.footerNav.contact') }}
            </a>
          </li>

          <li>
            <router-link :to="{name: 'tos'}">
              {{ translate('talron.footerNav.tos') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'privacy-policy'}">
              {{ translate('talron.footerNav.privacyPolicy') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'accessibility-statement'}">
              {{ translate('talron.footerNav.accessibilityStatement') }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="legal">
        כל הזכויות שמורות לטלרון בע״מ Ⓒ 2021
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data    : function () {
    return {}
  },
  computed: {
    ...mapGetters({
      navData     : 'talron/footerNav',
      'showFooter': 'talron/showFooter',
    }),
  }

}
</script>

<style scoped lang="scss">
.talron-footer {
  position   : relative;
  min-height : 60px;
  padding    : 12px 0;
  background : var(--global-secondary-background);
  color      : var(--global-inverse-color);
  z-index    : 1;

  .footer-inner {
    height : 100%;
  }

  .footer-menu {
    ul {
      list-style      : none;
      display         : flex;
      justify-content : center;
      flex-wrap       : wrap;
      margin-bottom   : 3px;

      li {
        position : relative;
        padding  : 0;
        display  : block;

        &:after {
          content : '|';
          padding : 3px 10px;

        }

        &:last-of-type::after {
          display : none;
        }

        a {
          color       : var(--global-inverse-color);
          font-size   : var(--font-xs);
          line-height : 1;

          &:hover {
            color           : var(--global-inverse-color);
            text-decoration : underline;
          }
        }


      }
    }
  }

  .legal {
    font-size : var(--font-xs);
    color     : var(--global-inverse-color);
  }
}
</style>
