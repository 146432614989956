<template>


    <div :class="wrapperClass"
         style="z-index: 0; position: relative"
         :style="wrapperStyle"
         :id="id"
         v-if="inputSubType != 'hidden'">

        <label  :for="fieldId"
                :class="labelClass"
                v-show="showLabelFinal"
                v-if="inputSubType !== 'checkbox'"

        >{{ucFirst(translateString(finalLabel))}}</label>
        <label  :for="fieldId"
                class="sr-only"
                :class="labelClass"
                v-if=" ! showLabelFinal && inputSubType !== 'checkbox'"
        >{{ translate(finalAriaLabel) }}</label>
        <div :class="controlsClass">
            <div class="validation-pending-spinner" v-if="validationPending"  :style="validationPendingStyle">
                <spinner
                        :show="true"
                        :inline="true"
                        :size="0.6"
                        class="validation-pending-spinner"
                ></spinner>
            </div>

            <div :class="inputInnerWrapperClass">

                <span class="uk-form-icon " v-if="icon" :uk-icon="icon"></span>
                <span class="uk-form-icon uk-form-icon-flip" v-if="iconEnd" :uk-icon="iconEnd"></span>
                <span class="uk-form-icon uk-form-icon-flip uk-form-icon-error uk-text-danger"
                    v-if="hasError && errorDisplay == 'slim'"
                    :uk-tooltip="errorMessage"
                    :uk-icon="'warning'"></span>

                <component :is="customInputComponent"
                           :validation="validation"
                           :aria-label="ucFirst(translateString(finalAriaLabel))"
                           :aria-invalid="inputAriaInvalid"
                           :aria-describedBy="'error-container'+fieldId"
                           v-if="inputSubType === 'custom'"
                           :id="fieldId"
                           :class="inputClass"
                           :style="inputStyle"
                           :name="name"
                           :type="type"
                           :placeholder="ucFirst(translateString(finalPlaceHolder))"
                           v-model="value"
                           v-bind="inputAttrs"
                           ref="formControl"

                ></component>

                <simple-input
                        v-if="inputSubType === 'input'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :aria-invalid="inputAriaInvalid"
                        :aria-describedBy="'error-container'+fieldId"
                        :aria-label="ucFirst(translateString(finalAriaLabel))"
                        :name="name"
                        :type="type"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="inputAttrs"
                        ref="formControl"
                />

                <text-area-input
                        v-if="inputSubType === 'textarea'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :aria-invalid="inputAriaInvalid"
                        :aria-describedBy="'error-container'+fieldId"
                        :aria-label="ucFirst(translateString(finalAriaLabel))"
                        :name="name"
                        :placeholder="ucFirst(translateString(finalPlaceHolder))"
                        v-model="value"
                        v-bind="$attrs"
                        ref="formControl"
                >{{value}}</text-area-input>

                <select-input
                        v-if="inputSubType === 'select'"
                        :id="fieldId"
                        :class="inputClass"
                        :style="inputStyle"
                        :aria-invalid="inputAriaInvalid"
                        :aria-describedBy="'error-container'+fieldId"
                        :aria-label="ucFirst(translateString(finalAriaLabel))"
                        :name="name"
                        :placeholder="finalPlaceHolder"
                        v-model="value"
                        :list="list"
                        v-bind="inputAttrs"
                >
                    <option v-for="option in list" :value="option.value">{{option.label}}</option>
                </select-input>
                <label v-if="inputSubType === 'checkbox'"
                       class="checkbox-label"
                       :class="labelClass"
                       :for="fieldId">
                    <div class="checkbox-wrapper-inner">
                         <simple-input type="checkbox"
                                       :id="fieldId"
                                       :class="inputClass"
                                       :aria-invalid="inputAriaInvalid"
                                       :aria-describedBy="'error-container'+fieldId"
                                       :aria-label="ucFirst(translateString(finalAriaLabel))"
                                       :name="name"
                                       v-model="value"
                                       v-bind="$attrs"/>
                        <span style="display: inline-block; margin-inline-start: calc(var(--global-margin) * 0.2);"  v-if=" htmlLabel" v-html="label"></span>
                        <span style="display: inline-block; margin-inline-start: calc(var(--global-margin) * 0.2);" v-if=" ! htmlLabel">{{ucFirst(translateString(finalLabel))}}</span>

                    </div>
               </label>

                <label v-if="inputSubType === 'radio'"
                       :class="labelClass"
                       v-for="(option, index) in list"
                       :for="fieldId + '-' + index">
                    <simple-input type="radio"
                           :aria-invalid="inputAriaInvalid"
                           :aria-describedBy="'error-container'+fieldId"
                           :aria-label="ucFirst(translateString(finalAriaLabel))"
                           :id="fieldId + '-' + index"
                           :class="inputClass"
                           :value="option.value"
                           :name="name + '-' + index"
                           v-model="value"
                           v-bind="$attrs"/>
                    {{ucFirst(translateString(option.label))}}
                </label>

            </div>

            <span class="uk-form-error-message uk-text-danger"
                  :id="'error-container'+fieldId"
                  :class="{'error-absolute-position' : (errorDisplayPosition == 'absolute')}"
                  v-if="hasError && errorDisplay == 'full'">
                <span class="sr-only">{{ translate('talron.general.fieldErrorSrPrefix', {name: translateString(finalAriaLabel)}) }}</span>
                {{errorMessage}}
            </span>

        </div>
    </div>

    <simple-input
            v-if="inputSubType === 'hidden'"
            :id="fieldId"
            :name="name"
            type="hidden"
            v-model="value"
            v-bind="inputAttrs"
            ref="formControl"
    />
</template>

<script>
    // TODO: implement state danger, success, disabled. decide how validation works, before implementing this
    /**
     * Interface for form controls of all kinds - select,radio, checkbox,textarea and all other inputs
     *
     * @displayName form-input
     */
    import useValidation from "@/client/extensions/composition/useValidation";
    import useVuelidate from '@vuelidate/core'

    import {reactive, watchEffect,  computed, getCurrentInstance, ref} from 'vue';

    import { minLength, required } from '@vuelidate/validators';

    export default {

        props: {
            ariaLabel: {
                default: null,
            },
            /**
             * Model value for v-model
             */
            modelValue    : {},
            /**
             * Should a label be displayed
             * Can pass false to force hide the label
             */
            showLabel     : {
                type: Boolean,
                default: true
            },
            /**
             * Label for the input
             * Used for any type of input. For checkboxes, shows next to input
             */
            label         : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Should label be injected as HTML
             * TODO: not fully implemented yet
             **/
            htmlLabel: {
                type: [Boolean],
                default: false,
            },
            /**
             * Name for input
             */
            name          : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Placeholder for the input/textarea
             */
            placeholder   : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Type of input - checkbox radio select and textarea have special treatment
             * Other values are used as type of a regular input element
             */
            type : {
                type: [String, Boolean],
                default: 'text'
            },
            /**
             * Size of the input
             * @values default, small, large
             **/
            size : {
                type: [String],
                default : 'default'
            },
            /**
             * List of options. Relevant for select and radio.
             * For radio, each option will create a radio+own label
             *
             * Input must be array of objects, each having a value and a label property
             */
            list: {
                type: [Array],
                default: []
            },
            /**
             * In case we are rendering a radio input (multiple radio inputs),
             * Should they be stacked visually? For horizontal forms, we get horizontal
             * radios unless this is true
             */
            radioStacked : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Class for the OUTER wrapper
             */
            class : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * ID for the outer wrapper. Input id is handled by the component separately.
             */
            id : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Icon for the input. It is advisable not to use in a checkbox, radio, select, textarea
             * Used as class name, works with any icon in the framework
             */
            icon : {
                type: [Boolean, String],
                default: false,
            },
            /**
             * Icon to be position after the slot
             **/
            iconEnd : {
                type: [Boolean, String],
                default: false,
            },
            /**
             * Error message to show on field. If not exactly '' -> field is considered "with error"
             */
            errorMessage : {
                type: [String, Array],
                default: ''
            },
          /**
           * Style of error display
           * @values 'full', 'slim',
           */
          errorDisplay : {
              type: [String],
              default: 'full',
          },
            /**
             * Time in miliseconds within which a field, which had error and now does not, will be green
             */
            errorFixIndicationDuration : {
                type: Number,
                default: 1500
            },
            /**
             * Used on form component. But we dont want that bound to the element itself, so we add this as a prop
             */
            contexts : {
                default() {return {};},
            },
            /**
             * Is field validation waiting (we use this for ui)
             */
            validation: {
                type: Object,
                default() {return {};},
            },
            /**
             * Is validation in progress
             */
            validationPending: {
                type: Boolean,
                default: false
            },
            /**
             * Should component render some margins automatically on wrapper
             */
            wrapperMargins: {
                type: Boolean,
                default: true
            },
            /**
             * Size of the input. Can be false for no limit, string for UK class, or integer for px
             * @values false, 'large','medium','small','xsmall', [any integer]
             */
            inputSize: {
                type: [Boolean, String, Number],
                default: false,
            },
            /**
             * Class for the inner input element
             */
            innerClass: {
                type: String,
                default: ''
            },
            autoTranslate : {
                type : Boolean,
                default: true
            },
            /**
             * Should we apply UI Framework classes to style this input
             */
            autoInputStyling : {
                type: Boolean,
                default: true
            },
            /**
             * Position of errors
             * Available options: relative, absolute
             **/
            errorDisplayPosition: {
                type: String,
                default: 'relative'
            },
            doValidations : {
                type: Boolean,
                default: false,
            },
            validationNamespace: {
                type: [String, Boolean],
                default: false,
            },
            wrapperStyle: {
                type: [Object, String],
                default: ''
            }
        },
        setup(props) {
            let internalInstance = getCurrentInstance();
            let component = internalInstance.proxy;


            if ( ! props.doValidations) {
                return {};
            }
           /*

             const validationModel = reactive({});

             validationKey = component.name;

            validationModel[validationKey] = component.modelValue;

            let validationKey = component.name;

            let {getValidationsByConfig} = useValidation(props);

            let rawRules = getValidationsByConfig(component.validation);

            let rules = {};
            rules[validationKey] = rawRules;


            const v$ = useVuelidate(rules, validationModel);


            let result = { v$, validationModel, validationKey};

            */

           /*
         //   result[validationKey] = state[validationKey];
            let {getValidationsByConfig} = useValidation(props);

            let validationKey = component.name;
            let result = {
                v$ : useVuelidate(),
                getValidationsByConfig,
                validationKey
            };
           // result[validationKey] = ref(component.modelValue);
*/
            return {};


        },
        inheritAttrs: false,

        data: function () {

            let result = {
                errorFixed : false,
            };



            return result;
        },
        computed : {
            value: {
                get() {
                    // for non text/textarea inputs, return value untouched
                    if (this.type === 'checkbox' || this.type === 'radio' || this.type === 'select') {
                        return this.modelValue;
                    }

                    // for text/textarea, cast to string if false
                    if (this.modelValue === false) {
                        return '';
                    }

                    // defer to returning the default value
                    return this.modelValue;
                },
                set(value) {
                    /**
                     * Updates model
                     */
                    this.$emit('update:modelValue', value)
                }
            },
            finalPlaceHolder () {
                return typeof this.placeholder === 'string' ? this.placeholder : '';
            },
            finalLabel () {
                return typeof this.label === 'string' ? this.label : '';
            },
            finalAriaLabel () {
                if (typeof this.ariaLabel === 'string') {
                    return this.ariaLabel;
                }

                if (typeof this.label === 'string') {
                    return this.label;
                }

                if (typeof this.placeholder === 'string') {
                    return this.placeholder;
                }
                return '';
            },
            showLabelFinal () {
                let forceHide = ! this.showLabel;
                let hasLabel  = typeof this.label === 'string' && this.label !== '';

                return hasLabel && ! forceHide;
            },
            inputStyle () {
                let styles = {};
                let propClass = this.innerClass.split(' ' );

                if (this.inputSize && typeof this.inputSize === 'number') {
                    styles['width'] = this.inputSize+'px';
                }

                // if select and has icon -padding
                if (this.type === 'select' && this.icon) {
                    styles['padding-inline-start'] = 'calc(var(--global-margin)*1.5)'
                }

                return styles;
            },
            inputAriaInvalid() {
                // error class
                let result = Boolean(this.hasError);
                if (result === false) {
                    return null;
                }
                return this.hasError;
            },
            inputClass () {
                let inputClass = [];
                let propClass = this.innerClass.split(' ' );

                // error class
                if (this.hasError) {
                    inputClass.push('uk-form-danger');
                }

                // error fixed indicator
                if (this.errorFixed) {
                    inputClass.push('uk-form-success');
                }

                // skip additional styleing if so desired
                if ( ! this.autoInputStyling) {
                    return [...inputClass, ...propClass];
                }

                // apply styiling
                if (this.inputSize && typeof this.inputSize === 'string') {
                    inputClass.push('uk-form-width-' + this.inputSize);
                }

                if (this.type === 'checkbox') {
                    inputClass.push('uk-checkbox');
                    return inputClass
                }

                if (this.type === 'radio') {
                    inputClass.push('uk-radio');
                    return inputClass
                }

                if (this.type === 'textarea') {
                    inputClass.push('uk-textarea');
                    return inputClass
                }

                // select and "input" can have size modifiers
                if (this.type === 'select') {
                    inputClass.push('uk-select');
                } else {
                    inputClass.push('uk-input');
                }

                if (this.size === 'large') {
                    inputClass.push('uk-form-large');
                }

                if (this.size === 'small') {
                    inputClass.push('uk-form-small');
                }

                return [...inputClass, ...propClass];
            },
            labelClass () {
                return ['uk-form-label']
            },
            wrapperClass () {
                let result =  ['input-wrapper'];

                if (this.wrapperMargins) {
                    result.push('uk-margin-bottom');
                }

                if (this.hasError) {
                    result.push('input-wrapper-error');
                }

                result.push(this.class);
                return result;
            },
            controlsClass () {
                let result = ['uk-form-controls'];

                if (this.hasError) {
                    result.push('uk-form-controls-error');
                }


                if (this.type === 'checkbox' || this.type === 'radio') {
                    result.push('uk-form-controls-text');
                }
                if (this.type === 'radio' && this.radioStacked) {
                    result.push('uk-form-controls--stacked');
                }

                return result
            },
            inputSubType () {
                if (this.type === 'hidden') {
                    return 'hidden';
                }

                if (this.type === 'checkbox') {
                    return 'checkbox'
                }
                if (this.type === 'radio') {
                    return 'radio'
                }

                if (this.type === 'select') {
                    return  'select'
                }

                if (this.type === 'textarea') {
                    return  'textarea'
                }

                if (['text','tel','email','number'].includes(this.type)) {
                    return 'input'
                }

                return 'custom';
            },
            customInputComponent () {
              return utilities.ucFirst(this.type) + 'Input';
            },
            fieldId () {
                return this.id || this.$.uid;
            },
            inputAttrs () {
                let inputAttrs  = {...this.$attrs, ...this.$props};
                // remove special cases that cause issues with vue or uikit
                delete inputAttrs.class;
                delete inputAttrs.id;
                delete inputAttrs.type;
                delete inputAttrs.size;
                delete inputAttrs.placeholder;
                delete inputAttrs.label;
                inputAttrs['class'] = this.innerClass || '';


                return inputAttrs;
            },
            inputInnerWrapperClass () {
                return ['uk-inline', 'input-inner-wrapper']
            },
            hasError () {
                return this.errorMessage !== '';
            },
            validationPendingStyle () {

                if (this.iconEnd && this.icon) {
                    return {'inset-inline-start': '-35px', 'inset-inline-end' : 'auto'}
                }

                if (this.iconEnd) {
                    return {'inset-inline-start': '8px', 'inset-inline-end': 'auto'}
                }

                return {'inset-inline-end': '8px', 'inset-inline-start': 'auto'}
            }
        },

        emits: ['update:modelValue'],
        methods: {
            ucFirst (val) {
                return utilities.ucFirst(val);
            },
            translateString(val) {

                if (this.autoTranslate) {

                    return this.safeTranslate(val);
                } else {
                    return val;
                }
            },
            focus() {

              try { // try custom focus behaviour
                this.$refs.formControl.focus();
              } catch (e){
                try {// oh... lets try to catch their element and trigger a "normal" focus
                  this.$refs.formControl.$el.focus();
                } catch(e) {
                  // we did everything we can do
                }
              }


            }
        },

        watch:  {
            hasError (hasError, hadError) {
              if ( hadError && ! hasError) {
                  this.errorFixed = true
              }
          },
            errorFixed() {
              if (this.validationPending) {
                  this.errorFixed = false;
                  return false;
              }

              if (this.modelValue === null) {
                  this.errorFixed = false;
                  return;
              }

              clearTimeout(this.errorFixedTimeout);
                this.errorFixedTimeout = setTimeout(()=>{
                  this.errorFixed = false;
              }, this.errorFixIndicationDuration)

            },

            modelValue: {
                handler(newVal) {
                    if (this.doValidations) {

                       // this[this.validationKey] = newVal;


                      //  console.log('updated local', this[this.validationKey]);
                    }
                },
                immediate: true,
            }
        },

        created () {

            this.errorFixedTimeout = '';
        },
        mounted () {
           // this.v$.$touch();
        }

    }

</script>

<style scoped lang="scss">

    .input-wrapper {

    }
    // support the "stacked" radio inside a horizontal form
    .input-wrapper-error {
        margin-bottom: calc(var(--global-margin) * 2);
    }

    .uk-form-controls--stacked label {
        width: auto;
        display: block;
        float: none;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .uk-form-controls {
        position: relative;

        .validation-pending-spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 5;
        }

    }

    .input-inner-wrapper {
        width: 100%;
        position: relative;


    }

    input {
        // remove firefox autofill yaqiness
        -webkit-appearance: none;
        appearance: none;
        filter: none;
    }

    .uk-form-icon-error {
      pointer-events: auto; // allow tooltip on icon
    }


    .uk-form-error-message.error-absolute-position {
         position: absolute;
         top: 100%;
         left: 0;
         width:100%;
    }

    .checkbox-label {
        cursor: pointer;
        display: inline;
        flex: auto;

        .checkbox-wrapper-inner {
            display: flex;
        }

        .uk-checkbox {
            margin-top: 2px;
            min-width: 16px;
        }
    }
</style>
