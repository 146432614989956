<template>

    <label  :for="labelFor">
        <slot v-if="showSlot"></slot>
        <span v-if=" ! showSlot">{{translate(label)}}</span>
    </label>
</template>

<script>
    export default {
        props: {
            modelValue    : {},
            for: {
                type : String,
                default: '',
            },
            showSlot: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },

        },
        data: function () {
            return {};
        },
        computed: {
            labelFor() {
                return this.for;
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
