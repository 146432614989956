<template>
    <anchor class="title-link back-link" @click="$router.go(-1)">
        <inline :uk-icon="icon" ></inline>
        <inline >
            <slot>{{text}}</slot>
        </inline>
    </anchor>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'back',
            },
            icon : {
                type: String,
                default: 'icon: arrow-left'
            }
        },
        data: function () {
            return {};
        }

    }
</script>

<style scoped lang="scss">

</style>
