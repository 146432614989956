<template >
    <span class="icon-text uk-flex uk-flex-middle">
        <span class="icon-text__icon" :class="iconClass" :uk-icon="icon"></span>
        <span class="icon-text__text" :class="textClass">
             <slot></slot>
        </span>

    </span>
</template>
<script>
    export default {
        props: {
            icon: {
                type: String,
                default: 'home'
            },
            iconClass: {
                type: String,
                default: ''
            },
            textClass: {
                type: String,
                default: ''
            }
        }
    }
</script>
<style scoped lang="scss">

    .icon-text__icon {
        min-width: calc(var(--global-margin) * 1);
        position: relative;
        top: -1px;
        margin-right: 0.2em;
    }
</style>
