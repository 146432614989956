import {ref} from 'vue';


let updateUserStateAndMaintainSession = async (store) => {
    /* we do not remember why this was here. but this caused bugs
    utilities.wait(500).then(async ()=>{
        await store.dispatch('user/validateToken');
    });
    */
    await store.dispatch('user/validateToken');

    //if we are not authenticated, make sure user status reflects that
    if ( ! store.getters['user/isAuthenticated'] ) {
        store.commit('user/setLoggedIn', false);
        store.commit('user/initialFetchComplete', true);
        return;
    }

    // we are logged in. poll JWTs when needed, store our login status and fetch user profile
    // make sure JWT is ready
    await store.dispatch('user/maintainSession', 5);

    // jwt is ready, we are logged in

    store.commit('user/setLoggedIn', true);

    // fetch user profile
    await store.dispatch('user/updateUserProfile');

    store.commit('user/initialFetchComplete', true);
};

export default {
    install (app) {
        let store = app.config.globalProperties.$store;
        // user state will be populated, if token available in session. Then, we will keep refreshing the session
        // if we are not connected, then when a token becomes available for any reason this will keep refreshing that new token
        updateUserStateAndMaintainSession(store);

        app.mixin({
            updated () {

            },
            created () {

            },
            mounted () {

            }
        })
    },

}
