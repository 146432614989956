<template>
    <div>
billrun tailwind base component
    </div>
</template>

<script>
    export default {
        data: function () {
            return {};
        }

    }
</script>

<style scoped lang="scss">

</style>
