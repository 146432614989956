<template>


        <div class="blob-wrapper">
            <main class="blob-wrapper-inner">
                <slot></slot>
            </main>

        </div>
</template>

<script>
export default {
    data: function () {
        return {};
    }


}
</script>

<style scoped lang="scss">

.blob-wrapper {
    margin: 80px auto 0 auto;
    background-color: var(--global-background);
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
    width: 100%;
    // full height - header - footer - margin from top
    min-height: calc(100vh - 60px - 60px - 80px);

    @media (max-width: 1200px) {
        max-width: 800px;
    }

    @media (min-width: 1200px) {
        margin: 80px auto 80px auto;
        border-radius: var(--border-radius-large);
        width: 90%;
        max-width: 1000px;
        min-height: 70vh;
    }

    @media (min-width: 1300px) {
        max-width: 1200px;
    }
}

.blob-wrapper-inner {
    padding: 30px 33px;

    @media (min-width: 1200px) {
        padding: 60px;
    }
}
</style>

<style lang="scss">
.title-separator {
    .content {
        color: var(--global-color)!important;
    }

    .line {
        background-color:  #ddd!important;
    }

}
</style>
