<template>
    <div class="decorated-title-container uk-margin-bottom">
        <h3 class="decorated-title uk-margin-remove">
            <span v-html="title"></span>
        </h3>
        <p class="uk-margin-small-top uk-margin-remove-bottom">
            <slot></slot>
        </p>
    </div>

</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '',
            }
        },
        data: function () {
            return {};
        }

    }
</script>

<style scoped lang="scss">

</style>
