<template>

    <div class="wrapper uk-flex uk-flex-gap" :class="class">

        <input v-model="value"
               style="width: 20%; cursor: pointer; height: 100%; appearance: none; background: transparent; border: none; -webkit-appearance: none"
               v-bind="inputAttrs"
               :type="'color'"

               ref="inputElement"

               @focus="isFocused = true"
               @blur="isFocused = false"/>
        <div class="preview" @click.prevent="$refs.inputElement.click()" style="cursor: pointer;">{{ value }}</div>
    </div>





</template>

<script>

    export default {
        props: {
            modelValue    : {},
            type: {},
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {
            return {
                isFocused: false,
                test: null
            };
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                delete attrs.class;
                return attrs;
            },
            compoutedInputType () {
                return this.isFocused ? 'color' : 'text'
            }
        },
        methods: {

        }

    }
</script>

<style scoped lang="scss">

</style>
