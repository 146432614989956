import { createMetaManager } from 'vue-meta'

export default {
    install (app) {

        let manager = createMetaManager({isSSR: utilities.isSSR()});

        app.use(manager);

        app.mixin({
            setup () {

            },
            updated () {

            },
            created () {

            },
            mounted () {

            }
        })
    },

}
