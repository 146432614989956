<template>

    <div :class="wrapperClass">
        <!--<div class="oot__label oot__label--start" @click="modelValue = false">{{translate(offLabel)}}</div>-->
        <div :class="controlsClass" >
            <div class="oot__controls-line" @click="modelValue = ! modelValue">
                <div class="oot__controls-knob"></div>
            </div>
            <div class="oot__label oot__label" @click="modelValue = ! modelValue">{{modelValue ? translate(onLabel) : translate(offLabel)}}</div>

        </div>

        <input v-model="value" type="hidden" v-bind="inputAttrs"/>
    </div>

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            onLabel: {
                type: String,
                default : 'core.form.toggle.defaultOn'
            },
            offLabel: {
                type: String,
                default : 'core.form.toggle.defaultOff'
            },
            translateLabels: {
                type: Boolean,
                default: true,
            },
            class : {
                type: String,
                default: ''
            },
            /**
             * Where should the label be displayed
             * @values 'end', 'start', 'both', 'none'
             */
            labelPosition: {
                type: String,
                default: 'both'
            }
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
            inputAttrs() {
              let attrs = {...this.$attrs};
              delete attrs.type;
                delete attrs.list;
              attrs.type = 'hidden';
              return attrs;
            },
            controlsClass () {
                let classArray = ['oot__controls'];

                if (this.modelValue) {
                    classArray.push('uk-active')
                }

                return classArray;
            },
            wrapperClass() {
                let result = ['on-off-toggle', 'oot',  'uk-flex uk-flex-center'];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },

            finalLabelPosition () {
                if (['end', 'start', 'both', 'none'].includes(this.labelPosition)) {
                    return this.labelPosition;
                }

                return 'both';
            },

        },
        watch: {
            modelValue: {
                // disallow non-boolean values
                handler: function (oldValue, newValue) {
                    let targetValue = newValue;

                    // in case the new value is nonsense, consider the old value
                    if (typeof targetValue === 'undefined') {
                        targetValue = oldValue;
                    }

                    // cast the final value into boolean
                    if (typeof targetValue !== 'boolean') {
                        this.$emit('update:modelValue', !!targetValue);
                    }

                },
                immediate: true
            }
        }


    }
</script>

<style scoped lang="scss">

    /*
    .oot__label {
        cursor: pointer;
        transition: color 150ms;

        &:hover {

            color: var(--global-primary-background);
        }
    }

    .oot__controls {
        width: calc(var(--global-margin) * 1.7);
        padding: 5px 0px;
        position: relative;
        margin: 0 calc(var(--global-margin) * 0.5);
        cursor: pointer;
        display: flex;

        .oot__controls-line {
            content: ' ';
            position: absolute;
            height: 6px;
            border-radius: 2px;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
            left: 0;
            background: var(--global-secondary-background);
            z-index: 1;
        }


        --knob-width: calc(var(--global-margin) * 0.65);

        .oot__controls-knob {
            content: ' ';
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            height: calc(var(--global-margin) * 0.65);
            width: var(--knob-width);
            z-index: 2;
            border-radius: 0px;
            background-color: var(--global-inverse-color);
            border: 1px solid var(--global-muted-color);
            transition: all 250ms ease;
            overflow: hidden;
            left: 10%;


            &::after {

            }
        }

        &.uk-active {
            .oot__controls-knob {
                left: calc(90% - var(--knob-width));
                background-color: var(--global-primary-background);
                border-color: var(--global-primary-background);
            }
        }


    }

    */

    .oot {
        .oot__controls {
            display: flex;

            .oot__controls-line {
                position: relative;
                border: 1px solid var(--global-muted-background);
                background: var(--global-danger-background);
                height: calc(var(--global-margin) + 4px);
                box-shadow: 0 0 6px 0px rgba(0,0,0,0.3) inset;
                width: calc(var(--global-margin) * 2.5);
                cursor: pointer;
            }

            .oot__controls-knob {
                position: absolute;
                background: var(--global-muted-background);
                height: calc(var(--global-margin) - 4px);
                width: calc(var(--global-margin) - 4px);
                top: 3px;
                left: 3px;
                transition: background 350ms, transform 350ms cubic-bezier(1,.09,.65,.96);
                box-shadow: 0 0 3px 0px rgba(0,0,0,1);
            }

            &.uk-active {
                .oot__controls-line {
                    background: var(--global-success-background);
                }

                .oot__controls-knob {
                    transform: translateX(calc( 1.5 * var(--global-margin) - 4px));

                }
            }

            [dir=rtl] {
                .oot__controls-knob {
                    left: auto;
                    right: 2px;
                }

                &.uk-active {
                    .oot__controls-knob {
                        transform: translateX(calc( -1 * 1.5 * var(--global-margin) - 4px));
                    }
                }
            }
        }

        .oot__label {
            cursor: pointer;
            margin-inline-start: calc(var(--global-margin) * 0.25);
        }
    }
</style>
