<template>
    <div class="feature-block uk-flex uk-flex-column uk-flex-canter"
         @click="handleClick">
        <span class="feature-block__icon" v-if="icon" :uk-icon="icon"></span>
        <inline-block class="feature-block__text">
            <slot>
                {{finalText}}
            </slot>
        </inline-block>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: [String, Boolean],
            default: false,
        },
        text: {
            type: [String, Boolean],
            default: false,
        },
        navAction: {
            type: [Object, Boolean],
            default: false,
        }
    },
    data: function () {
        return {};
    },
    computed: {
        finalText() {
            if (typeof this.text === 'string') {
                return this.safeTranslate(this.text);
            }

            return '';

        },
        wrapperCursor() {
            return this.navAction ? 'pointer' : 'default';
        }
    },
    methods: {
        handleClick(e) {
            if (this.navAction) {
                this.$router.push(this.navAction);
            }
        }
    }

}
</script>

<style scoped lang="scss">
    .feature-block {
        //this is enabled by vue 3.2. All praise the mighty Evan You
        cursor: v-bind('wrapperCursor');

        max-width: 104px;
        text-align: center;
        color: var(--global-primary-background-dark);
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;

        &__icon {
            display: flex;
            align-items: bottom;
            justify-content: center;
            height: 25px;
            width: 40px;
            justify-self: center;
            margin: 0 auto;
            margin-bottom: 5px;
        }
    }
</style>
