
export default new Promise(async (resolve) => {
    let plugin = {
        install (app) {
            app.mixin({
                //url = utilities.requireAsset('images/selfcare/backgrounds/' + this.backgroundImage);
                methods: {
                    getDynamicAssetUrl(arg) {
                        return utilities.requireAsset(arg);
                    }
                }
            });
        }
    };
    resolve(plugin);
});
