<template>
    <div class="steps steps--tight">
        <div class="uk-flex uk-flex-top">
            <div class="uk-flex step uk-width-expand"
                 :class="{
                 locked: step.locked,
                 complete: step.complete,
                  current: stepIndex == currentStep,
                  'no-tail' : stepIndex === 0,
                  'no-nav': ! allowStepNav

                  }"
                 :style="{'z-index': steps.length - stepIndex}"
                 v-for="(step, stepIndex) of steps"

                 :key="step.number">
                <div class="step-content uk-width-expand uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                    :class="{}">
                    <div class="step-number uk-flex uk-flex-center uk-flex-middle"
                         @click="attemptStepChange(stepIndex)"
                    >{{stepIndex + 1}}</div>
                    <div class="step-text uk-text-center">
                        <span>
                             {{step.label}}
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {

            steps: {
                type: Array,
                default: []
            },
            activationSteps: {
                type: Array,
                default: []
            },
            currentStep: {
                type: Number,
                default: 0,
            },

            /**
             * Should the activationSteps be used as nav, which can be clicked to navigate in the activationSteps
             */
            allowStepNav: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {

            };
        },
        emits: ['activationSteps:requestChangeStep'],
        methods: {
            attemptStepChange(stepIndex) {
                let step = this.activationSteps[stepIndex] || false;

                if ( ! this.allowStepNav) {
                    return;
                }

                if ( ! step) {
                    return;
                }

                if (step.locked) {
                    return;
                }

                this.$emit('activationSteps:requestChangeStep', stepIndex)
            }
        }

    }
</script>

<style scoped lang="scss">

    .steps {
        overflow-x: hidden;
    }

    .step {
        --step-number-height: calc(var(--global-margin) * 1.6);
        --tail-height: calc(var(--global-margin) * 0.1);
        position: relative;


        .step-content {
            position: relative;
            z-index: 2;


        }

        .step-number {
            background-color: var(--global-muted-color);
            color: var(--global-inverse-color);
            height: var(--step-number-height);
            width: var(--step-number-height);
            border-radius: var(--border-radius-small);
            font-size: calc(var(--global-font-size) * 1.1);
            transition: background-color 350ms ease;
        }

        .step-text {
            position: relative;
            top: calc(var(--global-margin) * -0.05);
            font-size: calc(var(--global-font-size) * 0.8);
        }

        // "tail" to the left of the number
        &::after {
            z-index: 1;
            display: block;
            content: ' ';
            position: absolute;
            top: calc(var(--step-number-height) / 2 - var(--tail-height)/2);
            right: 50%;
            width: 100%;
            height: var(--tail-height);
            background-color: var(--global-muted-color);
            transition: background-color 350ms ease;
        }

        &.no-tail {
            &::after {
                display: none;
            }
        }


        &.complete {
            .step-number {
                background-color: var(--global-primary-background);
                color: var(--global-inverse-color);
            }

            &::after {
                background-color: var(--global-primary-background);
            }
        }

        &.current {
            .step-number {
                background-color: var(--global-primary-background);
                color: var(--global-inverse-color);
            }

            &::after {
                background-color: var(--global-primary-background);
            }
        }

        &.locked {
            cursor: not-allowed
        }

        &:not(.locked) {
            cursor: pointer;
        }

        &.no-nav,
        &.no-nav.locked,
        &.no-nav:not(.locked) {
            cursor: default;
        }
    }
</style>
