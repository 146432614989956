<template>

    <div :class="wrapperClass">
        <div class="uk-flex color-list">

            <div class="color-item"
                 v-for="item in list"
                 :class="{'selected': item.value == value}"
                 @click="this.value = item.value">
                <div class="inner" :style="'background-color:'+item.color+';'"></div>
            </div>
        </div>

        <input v-model="value" type="hidden" v-bind="inputAttrs"/>
    </div>

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {
                type: Array,
                default: []
            },
            class : {
                type: String,
                default: ''
            },
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value);
                    return true;
                }
            },
            inputAttrs() {
              let attrs = {...this.$attrs};
              delete attrs.type;
              delete attrs.list;
              attrs.type = 'hidden';
              return attrs;
            },
            controlsClass () {
                let classArray = [];


                return classArray;
            },
            wrapperClass() {
                let result = ['color-select-wrapper'];
                result.push(this.class.replace('uk-input', ''));
                return result;
            },

        },
        watch: {
            modelValue: {
                handler: function (oldValue, newValue) {
                   //

                },
                immediate: true
            }
        },



    }
</script>

<style scoped lang="scss">

    // specific selector to override UIkit
    .color-select-wrapper.uk-form-large:not(textarea):not([multiple]):not([size]) {
        padding-left: 0;
        padding-right: 0;
    }

    .color-list {
        box-sizing: border-box;



        * {
            box-sizing: border-box;
        }

        .color-item {
            margin-inline-end: calc(var(--global-margin) * 0.3);
            height:  calc(var(--global-margin) * 1.25);
            width:  calc(var(--global-margin) * 1.25);
            border: 1px solid transparent;
            border-radius: 50%;
            padding: 3px;
            cursor: pointer;
            transition: border-color 150ms;


            &.selected {
                border-color: var(--global-secondary-background);
            }

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                transition: box-shadow 150ms;
            }

            &:hover {
                .inner {
                    box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
                }
            }

            &.selected:hover {
                .inner {
                    box-shadow: none;
                }
            }

        }
    }

    .uk-form-large .color-list {
        .color-item {
            margin-inline-end: calc(var(--global-margin) * 0.5);
            height:  calc(var(--global-margin) * 1.75);
            width:  calc(var(--global-margin) * 1.75);
            border-width: 2px;
            border-radius: 50%;
            padding: 4px;

            &:hover {
                .inner {
                    box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
                }
            }

            // despite not being an override in itself, this is required
            &.selected:hover {
                .inner {
                    box-shadow: none;
                }
            }

        }
    }
</style>
