<template>
    <input type="text" v-bind="inputAttrs"
            v-model="value"
   />

</template>

<script>
    export default {
        props: {
            modelValue    : {},
            list: {}, // prevent inheritence of list="" on element

        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {

            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            maxCharecters () {
                if ( ! this.characterCounter) {
                    return 0;
                }

                return this.characterCounter.max || this.translate('core.unlimited');
            },
            currentCharecters () {
                return this.modelValue.length;
            }
        },
    }
</script>

<style scoped lang="scss">

    .character-counter {
        display: block;
    }
</style>
