<template>

    <div :class="overlayClass"
         v-show="show"
         v-if="showOverlay"
         v-bind="$attrs"
         :style="overlaySpinnerStyle"
         >
        <div class="spinner-wrapper">
            <div :uk-spinner="spinnerAttributes" v-if="showSpinner"></div>
            <div class="spinner-text" v-if="hasText">{{finalSpinnerText}}</div>
            <slot></slot>

            <progress class="uk-progress"
                      :value="progress"
                      max="100"
                      v-if="hasProgress"></progress>
        </div>
    </div>

    <div :style="noOverlaySpinnerStyle"
         :class="nonOverlayWrapperClass"
         v-if=" ! showOverlay"
         v-show="show"
         v-bind="$attrs">
        <div class="inner">
            <div :uk-spinner="spinnerAttributes" v-if="showSpinner"></div>
            <div class="spinner-text" v-if="hasText" v-show="show">{{translate(text)}}</div>
            <slot></slot>
            <progress class="uk-progress" :value="progress" max="100" v-if="hasProgress" v-show="show"></progress>
        </div>
    </div>
</template>

<script>
    /**
     * Spinner
     * @displayName spinner (frameworks/uikit - loaders)
     */
    export default {
        props: {
            /**
             * Is the spinner showing? When false, this has no html output
             * @values true, false
             */
            show : {
                type : Boolean,
                default: true
            },
            /**
             * Show the actual spinner
             **/
            showSpinner: {
                type: Boolean,
                default: true,
            },
            /**
             * Size of spinner For this uikit implementation, this is used as the spinner ratio
             * If set to true (boolean) will use size based on type of overlay
             * @see https://getuikit.com/docs/spinner#ratio
             *
             */
            size: {
                type: [Number, String, Boolean],
                default: true
            },
            /**
             * Should this be shown as an overlay?
             * Uses CSS styles for fixed to cover entire page,
             * absolute cover entire positioned parent
             *
             * Any value other than fixed or absolute is evaluated to false
             *
             * @values fixed, absolute, *
             *
             */
            overlay : {
                type: [Boolean, String],
                default: false,
            },
            /**
             * Should the outer wrapper of the spinner component be inline
             * @values true, false
             */
            inline : {
                type : [Boolean],
                default: false,
            },
            /**
             * Text to show next to the spinner. Default is not to show text
             *
             */
            text: {
                type: [String],
                default : '',
            },
            /**
             * Class for external wrapper
             */
            class: {
                type: [String],
                default: '',
            },
            /**
             * Should a progress bar be shown?
             * Defaults to false. If a number is provided, progress bar will be shown
             * with [number]% progress.
             */
            progress : {
                type: [Boolean, Number],
                default: false,
            },
            /**
             * If using overlay, should we override the default opacity
             * If number is provided, this will be the opacity (rgba(255,255,255,[number])
             * If "light" or "dark" a preset value is used.
             * Any other value results in the default
             * @values light, dark, [integer]
             */
            overlayOpacity : {
                type: [Boolean, Number, String],
                default: false,
            },
            /**
             * Css mouse courser
             */
            cursor: {
                type: String,
                default: 'wait'
            },
            /**
             * Auto translate spinner text?
             */
            autoTranslate: {
                type: Boolean,
                default: true
            }
        },
        data: function () {
            return {};
        },
        computed : {
            spinnerAttributes : function () {
                let ratio = this.size;

                if (ratio === true && this.overlay === 'absolute') {
                    ratio = 2;
                    return "ratio: " + 2;
                }

                if (ratio === true && this.overlay === 'fixed') {
                    return "ratio: " + 3;
                }

                if (ratio === true) {
                    return "ratio: " + 1;
                }

                return "ratio: " + ratio;
            },
            showOverlay : function () {
                return this.overlay === 'absolute' || this.overlay === 'fixed' || this.overlay;
            },
            overlayClass : function () {
                let val = this.overlay;
                if (this.overlay === true) {
                    val = 'default';
                }

                return ['spinner-overlay-wrapper', 'spinner-overlay-wrapper--'+val, this.class];
            },
            nonOverlayWrapperClass : function () {
                let classes = ['spinner-wrapper', 'spinner-wrapper--no-overlay' , this.class];
                if (this.inline) {
                    classes.push('spinner-wrapper--inline');
                }
                return classes;
            },
            hasText: function () {
                return this.text !== '';
            },
            hasProgress: function () {
                return typeof this.progress === 'number';
            },
            overlayBackground: function () {
                let val = "rgba(255,255,255,0.8)";

                if (typeof this.overlayOpacity === 'string') {
                    switch(this.overlayOpacity) {
                        case 'none':
                        case 'transparent':
                            return "rgba(255,255,255,0.00)";
                            break;
                        case 'light':
                            return "rgba(255,255,255,0.20)";
                            break;
                        case 'dark':
                            return "rgba(255,255,255,0.85)";
                            break;

                    }
                }

                if (typeof this.overlayOpacity === 'number') {
                    return "rgba(255,255,255," + this.overlayOpacity + ")";
                }

                // use default
                return val;
            },
            overlaySpinnerStyle () {
                return {'backgroundColor': this.overlayBackground, cursor : this.cursor}
            },
            noOverlaySpinnerStyle () {
                return {cursor : this.cursor};
            },
            finalSpinnerText() {
                if (this.autoTranslate) {
                    return this.translate(this.text);
                } else {
                    return this.text;
                }
            }


        }

    }
</script>

<style scoped lang="scss">
    .spinner-overlay-wrapper {
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 50;
            background: rgba(255,255,255, 0.45);
            display: flex;
            justify-content: center;
            text-align: center;

            &.spinner-overlay-wrapper--absolute {
                position: absolute;
            }

            &.spinner-overlay-wrapper--fixed {
                position: fixed;
            }

            .spinner-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                > *  {
                   margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .uk-spinner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

    .spinner-wrapper--inline, .spinner-wrapper--no-overlay.spinner-wrapper--inline {
        display: inline-block;
        vertical-align: middle;
    }
    .spinner-wrapper--no-overlay {
        display: flex;

       .inner {
           display: flex;

           > * {
               -webkit-margin-inline-end: 8px;
               margin-inline-end: 8px;

               &:last-child {
                   -webkit-margin-inline-end: 0;
                   margin-inline-end: 0;
               }
           }
       }

    }
</style>
