<template>


    <div class="blob-external uk-background-muted" >
         <h1 aria-live="assertive"  tabindex="0"
            class="blob-external-title uk-text-bold uk-width-expand uk-flex uk-flex-middle uk-flex-canter uk-text-center tr-font-l ">
            <span class="uk-text-center uk-width-expand">{{ translate(title) }}</span>
        </h1>
        <div class="blob-wrapper ">
            <div class="blob-wrapper-inner">
                <slot></slot>
            </div>

        </div>
    </div>

</template>

<script>
export default {
    props: {
        title: {
            default: null,
        }
    },
    data: function () {
        return {

        };
    }


}
</script>

<style scoped lang="scss">
.blob-external-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 75px;
    margin: 0;

    @media (max-width: 1200px) {
        font-size: 17px;
    }

    @media (min-width: 1200px) {
        padding-top: 40px;

    }
}
.blob-wrapper {
    margin: 0 auto 0 auto;
    background-color: var(--global-background);
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
    width: 100%;
    filter: drop-shadow(0 0 20px rgba(0,0,0, 0.15));
    // full height - header - footer
    min-height: calc(100vh - 60px - 60px );

    @media (max-width: 1200px) {
        max-width: 800px;
    }

    @media (min-width: 1200px) {
        margin: 40px auto 80px auto;
        border-radius: var(--border-radius-large);
        width: 90%;
        max-width: 1000px;
        min-height: 70vh;
    }

    @media (min-width: 1300px) {
        max-width: 1200px;
    }
}

.blob-wrapper-inner {
    padding: 30px 33px;

    @media (min-width: 1200px) {
        padding: 60px;
    }
}
</style>

<style lang="scss">
.title-separator {
    .content {
        color: var(--global-color)!important;
    }

    .line {
        background-color:  #ddd!important;
    }

}
</style>
