<template>

    <div class="default-layout default-layout--plain">
        <!-- todo: consider: move to header component -->
        <header class="talron-header uk-margin-medium-bottom" style="box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.11);;"
        :class="{'uk-visible@m' :   ! showHeader}">
            <span class="talron-menu-bars" uk-icon="talron-menu-bars"></span>
            <span v-if="showDevLogout" @click="talronLogout"
                  style="position: absolute; font-weight: bold;top: 20px; inset-inline-end: 20px;cursor:pointer;"
            ><span uk-icon="sign-out"></span></span>
            <img class="logo" :src="getDynamicAssetUrl('images/logos/logo.png')" alt="">
        </header>

        <div class="content-external-wrapper">
            <main class="main-content uk-container" style="background: transparent;">
                <slot></slot>
            </main>
        </div>


        <!-- todo: consider: move to footer component -->
        <footer class="talron-footer" v-if="showFooter">
            <div class="footer-inner uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-container uk-margin-auto">
                <div class="footer-menu">
                    <ul>
                        <li>
                            <a href="#">צור קשר</a>
                        </li>
                        <li>
                            <a href="#">תנאי שימוש</a>
                        </li>
                        <li>
                            <a href="#">מדיניות פרטיות</a>
                        </li>
                    </ul>
                </div>
                <div class="legal">
                    כל הזכויות שמורות לטלרון בע״מ Ⓒ 2021
                </div>
            </div>
        </footer>
    </div>



</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        props: {
            /*
            backgroundImage: {
                type: String,
                default: 'page-bg-texture.png',
            }
            */
            renderH1: {
                type: Boolean,
                default: true
            }
        },
        components: {

        },
        data () {
            return {
               // showHeader: this.$store.getters['talron/showHeader'],
            }
        },
        computed: {
            ...mapGetters({
                  'showHeader' : 'talron/showHeader',
                  'showFooter' : 'talron/showFooter',
                  'appActionState' :   'talron/appActionState',
              }),
            showDevLogout() {
                if ( ! this.$store.getters['talron/hasTalronUser']) {
                    return false;
                }

                if (process.env.NODE_ENV === "development") {
                    return true
                }

                return false;

            },


        },
        methods: {
            async talronLogout() {
                await this.$store.dispatch('talron/logoutTalronUser');
                this.$router.push(config.user.guestLoginRedirect);
            }
        },
        mounted () {
        /*
            setTimeout(() => {
                console.log('success');
                this.$store.commit('talron/showHeader', false);
            }, 2000);
*/
/*
            setTimeout(() => {
                console.log('success');
                this.$store.commit('talron/appActionState', 'success');
            }, 1000);

            setTimeout(() => {
                console.log('default');
                this.$store.commit('talron/appActionState', 'default');
            }, 2000);

            setTimeout(() => {
                console.log('error');
                this.$store.commit('talron/appActionState', 'error');
            }, 3000);

            setTimeout(() => {
                console.log('reset');
                this.$store.commit('talron/resetAppActionState');
            }, 4000);
*/

        }
    }
</script>

<style lang="scss" scoped >

.default-layout {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: stretch;
    justify-content: space-between;
}

.content-external-wrapper {
    position: relative;
    min-height: 100px;
    width: 100%;
    background-repeat: repeat;
    background-size: auto;
    flex: 100%;
    z-index: 2;
    transition: all 300ms ease;
}

.talron-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white;
    padding: 10px 20px;
    z-index: 1;

    .talron-menu-bars {
        position: absolute;
        display: inline-block;
        top: 50%;
        inset-inline-start: 20px;
        width: 17px;
        height: 12px;
        margin-top: -6px;
        line-height:0;
        cursor: pointer;
    }

    svg {
        width: 17px;
        height: 12px;
    }

    .logo {
        height: 40px;
        width: auto;
    }
}

.talron-footer {
    position: relative;
    min-height: 60px;
    padding: 12px 0;
    background: var(--global-secondary-background);
    color: var(--global-inverse-color);
    z-index: 1;

    .footer-inner {
        height: 100%;
    }

    .footer-menu {
        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 3px;

            li {
                position: relative;
                padding: 0;
                display: block;

                &:after {
                    content: '|';
                    padding: 3px 10px;

                }

                &:last-of-type::after {
                    display: none;
                }

                a {
                    color: var(--global-inverse-color);
                    font-size: var(--font-xs);
                    line-height: 1;

                    &:hover {
                        color: var(--global-inverse-color);
                        text-decoration: underline;
                    }
                }


            }
        }
    }

    .legal {
        font-size: var(--font-xs);
        color: var(--global-inverse-color);
    }
}

</style>
