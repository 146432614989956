<template>

    <div class="barcode-input uk-input" style="height: auto;" >
        <input v-model="value"
               v-bind="inputAttrs"
               style="border: none; height: 100%; width: calc(100% - 35px);" />
        <span class="uk-form-icon uk-form-icon-flip uk-icon uk-hidden@l "
              uk-icon="barcode"
              style="z-index: 5; pointer-events: all;"
              @click.prevent="startScan"></span>

        <input style="display: none;"
               accept="image/*"
               capture
               type="file" ref="scanFileInput"
               @change="processFileField" />

        <teleport to="body">
            <div class="tr-scan-display-wrapper"  v-show="scanInProgress">
                <spinner :show="scannerLoading || isLoading"
                         text="talron.scan.scannerLoading"
                         overlay="absolute"></spinner>
                <div class="overlay-top">
                    <div class="overlay-top-inner">
                        <span>{{translate('talron.scan.instructions')}}</span>
                    </div>
                </div>
                <div class="scan-display"  ref="scanContainer" id="test-scan-container"></div>

                <div class="overlay-bottom">
                    <div class="overlay-bottom-inner">
                        <div class="uk-flex uk-flex-center">
                            <form-button style="margin: 0;" @click.prevent="showHelp">{{translate('talron.scan.helpCta')}}</form-button>
                        </div>
                        <div class="cancel-cta uk-margin-top uk-flex uk-flex-center">
                            <a href="#" @click.prevent="stopScan">{{translate('talron.scan.cancelCta')}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <modal ref="helpModal" @modal:closed="">
                <template #default>

                    <div class="message uk-text-center">
                        <h3 class="tr-font-title  " style="margin-bottom: 6px;">
                            {{translate('talron.scan.scannerHelpTitle')}}
                        </h3>
                        <div class="image-block uk-flex uk-flex-center uk-margin-top uk-margin-bottom">
                            <img :src="getDynamicAssetUrl('images/battery-success.svg')" alt=""
                                 style="height: 47px; width: auto;margin: 0 auto;"/>
                        </div>
                        <span class="" style="font-size: 17px;">
                            {{translate('talron.scan.scannerHelpText')}}
                        </span>
                    </div>
                </template>
                <template #footer>
                    <div class="footer-inner">
                        <button class="uk-button uk-button-primary"
                                type="button"
                                @click.prevent="hideHelp()">{{translate('talron.scan.scannerHelpDismissCta')}}</button>
                    </div>
                </template>
            </modal>

        </teleport>


    </div>

</template>


<script>
import Quagga from 'quagga';
import barcodeValidator from '@/client/applications/talron/extensions/validation/validators/batteryBarcode.js';

    export default {
        props: {
            modelValue    : {
                default() {return '';},
            },
            class : {},
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {

            return {
                scanValidationDebounce : null,
                scanInProgress :false,
                scannerLoading: false,
                isLoading: false,
            };
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue ? this.modelValue : '';
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
        },
        methods: {
            showHelp () {
                this.$refs['helpModal'].openModal();
            },
            hideHelp () {

                this.$refs['helpModal'].closeModal();
            },
            reportScanFailed() {
                this.$s.ui.notification.closeAll();
                this.$s.ui.notification(this.translate('talron.scan.stillsBarcodeScanFailed'),'warning');
                this.isLoading = false;
            },
            async processFileField() {
                this.value = '';
                const src = URL.createObjectURL(this.$refs.scanFileInput.files[0])
                const quaggaScanConfig =  {
                                decoder: {
                                    readers: [
                                        "code_128_reader",
                                        //     "ean_reader",
                                        //    "ean_8_reader",
                                        //   "code_39_reader",
                                        //   "code_39_vin_reader",
                                        //  "codabar_reader",
                                        //   "upc_reader",
                                        //   "upc_e_reader",
                                        //  "i2of5_reader"
                                        //     "2of5_reader",
                                        //  "code_93_reader",









                                    ],
                                    debug: {
                                        showCanvas: true,
                                        showPatches: true,
                                        showFoundPatches: true,
                                        showSkeleton: true,
                                        showLabels: true,
                                        showPatchLabels: true,
                                        showRemainingPatchLabels: true,
                                        boxFromPatches: {
                                            showTransformed: true,
                                            showTransformedBox: true,
                                            showBB: true
                                        }
                                    }
                                },
                                  src
                            };

               this.isLoading = true;
               Quagga.decodeSingle(quaggaScanConfig, async (result) => {
                   console.log('scan result', result);

                   if ( ! result.codeResult) {
                       return this.reportScanFailed();
                   }
                   let code = result.codeResult.code;
                   code = code.replace(/[^A-Za-z0-9]/g, '');

                   // optional validation
                   let isValid = ! config.talron.validateScannedBarcodes || await barcodeValidator.$validator(code);

                   if ( ! isValid) {
                       return this.reportScanFailed();
                   }

                   // result is acceptable. log it
                   this.value = code;
                   this.isLoading = false;
               });
            },
            startScan() {
                this.$refs.scanFileInput.click();
            },
        },
        mounted () {

        },
        created () {

        }

    }
</script>

<style scoped lang="scss">
.tr-scan-display-wrapper {
    top: 0;
    left: 0;
    background: rgba(255,255,255, 1);
    position: fixed;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    z-index: 200;

    .scan-display {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        min-width: 100vw;
        z-index: 20;
    }

    .overlay-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 30px 20px;
        background: rgba(0,0,0,0.7);
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--global-background);
        font-weight: bold;
        z-index: 22;
        text-align: center;
        line-height: 1.25;
    }

    .overlay-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 20px;
        background: rgba(0,0,0,0.7);
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--global-background);
        font-weight: bold;
        z-index: 22;
    }

    .cancel-cta  {
        a {
            color: var(--global-background);
            text-decoration: underline;
            font-size: 14px;

            @media screen and (min-width: 1024px) {
                font-size: 16px;
            }
        }

    }

}

</style>
<style lang="scss">
.tr-scan-display-wrapper {
    z-index: 200;
    video, .drawingBuffer { // injected by Quagga, must be non-scoped
        position: absolute;
        left:0;
        top: 50%;
        transform:  translateY(-50%);
        max-width: 100%;
        max-height: 100%;
    }

    .scan-display  {
        position: relative;
    }
    video {
        position: relative;
        border: 2px solid #ddd;
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.5);
        min-width: 100%;
        background: transparent;

    }
}


</style>
