<template>

    <div class="barcode-input uk-input" style="height: auto;" >

        <input v-model="value"
               v-bind="inputAttrs"
               style="border: none; height: 100%; width: calc(100% - 35px);" />
        <span class="uk-form-icon uk-form-icon-flip uk-icon uk-hidden@l mobile-scan-icon"
              uk-icon="barcode" style="z-index: 5; pointer-events: all;" @click.prevent="startScan"></span>

        <teleport to="body">
            <div class="tr-scan-display-wrapper"  v-show="scanInProgress">
                <spinner :show="scannerLoading"
                         text="talron.scan.scannerLoading"
                         overlay="absolute"></spinner>
                <div class="overlay-top">
                    <div class="overlay-top-inner">
                      <span>{{translate('talron.scan.instructions')}}</span>
                      <div class="cancel-cta uk-margin-top uk-flex uk-flex-center">
                        <a href="#" @click.prevent="stopScan">{{translate('talron.scan.cancelCta')}}</a>
                      </div>
                    </div>
                </div>
                <div class="scan-display"  ref="scanContainer" id="test-scan-container"></div>
                <div class="overlay-bottom">
                    <div class="overlay-bottom-inner">
                        <div class="uk-flex uk-flex-center">
                            <form-button style="margin: 0;" @click.prevent="showHelp">{{translate('talron.scan.helpCta')}}</form-button>
                        </div>
                        <div class="cancel-cta uk-margin-top uk-flex uk-flex-center">
                            <a href="#" @click.prevent="stopScan">{{translate('talron.scan.cancelCta')}}</a>
                        </div>
                    </div>
                </div>
            </div>

            <modal ref="helpModal" @modal:closed="">
                <template #default>

                    <div class="message uk-text-center">
                        <h3 class="tr-font-title  " style="margin-bottom: 6px;">
                            {{translate('talron.scan.scannerHelpTitle')}}&lrm;
                        </h3>
                        <div class="image-block uk-flex uk-flex-center uk-margin-top uk-margin-bottom">
                            <img :src="getDynamicAssetUrl('images/battery-success.svg')" alt=""
                                 style="height: 47px; width: auto;margin: 0 auto;"/>
                        </div>
                      <div style="direction:rtl;"></div>
                        <span class="" style="font-size: 17px; direction:rtl;">
                            {{translate('talron.scan.scannerHelpText')}}&lrm;
                        </span>
                    </div>
                </template>
                <template #footer>
                    <div class="footer-inner">
                        <button class="uk-button uk-button-primary"
                                type="button"
                                @click.prevent="hideHelp()">{{translate('talron.scan.scannerHelpDismissCta')}}</button>
                    </div>
                </template>
            </modal>

        </teleport>


    </div>

</template>


<script>
import Quagga from 'quagga';
import barcodeValidator from '@/client/applications/talron/extensions/validation/validators/batteryBarcode.js';
import addWindowScript from "@/client/extensions/composition/addWindowScript";
import * as  ScanditSDK  from "scandit-sdk";

    export default {
        props: {
            modelValue    : {
                default() {return '';},
            },
            class : {},
            list: {} // prevent inheritence of list="" on element
        },
        setup(props) {
          let {addScript} = addWindowScript() ;

          return {addScript};
        },
        data: function () {

            return {
                licenseKey: config.talron.scanditApiKey,
                engineUrl: 'https://unpkg.com/scandit-sdk@5.x/',
                scanValidationDebounce : null,
                scanInProgress :false,
                scannerLoading: false,
                barcodePicker : false,
                sdkReady: false,
            };
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue ? this.modelValue : '';
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
        },
        methods: {
            showHelp () {
                this.$refs['helpModal'].openModal();
            },
            hideHelp () {

                this.$refs['helpModal'].closeModal();
            },
            async startScan() {
                if ( utilities.isSSR()) {
                    return true;
                }
                this.scannerLoading = true;
                this.scanInProgress = true;
                this.value = '';

                if ( ! this.sdkReady) {
                    await ScanditSDK.configure(this.licenseKey, {
                        engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/'
                    });

                    this.sdkReady = true;
                }

                if ( this.barcodePicker) {
                    console.log('scanner already running');
                    this.scannerLoading = false;
                    return true;
                }
                console.log('create scanner');
                this.barcodePicker = await ScanditSDK.BarcodePicker.create(this.$refs.scanContainer, {
                    playSoundOnScan: true,
                    vibrateOnScan: true,
                });

                const scanSettings = new ScanditSDK.ScanSettings({
                                                                     enabledSymbologies: [ScanditSDK.Barcode.Symbology.CODE128],
                                                                     codeDuplicateFilter: 100, // Minimum delay between duplicate results
                                                                 });
                this.barcodePicker.applyScanSettings(scanSettings);

                this.barcodePicker.on("scan", async (scanResult) => {
                    let code = scanResult.barcodes[0].data;
                    code = code.replace(/[^A-Za-z0-9]/g, '');
                    console.log('scan', scanResult, code);
                    // optional validation
                    let isValid = ! config.talron.validateScannedBarcodes || await barcodeValidator.$validator(code);

                    if ( ! isValid) {
                        clearTimeout(this.scanValidationDebounce);
                        this.scanValidationDebounce = setTimeout( () => {
                            this.$s.ui.notification.closeAll();
                            this.$s.ui.notification(this.translate('talron.scan.badBarcodeScannedNotification'),'warning');
                        }, 750);
                        return;
                    }

                    // clear queued validation messages - not relevant anymore
                    clearTimeout(this.scanValidationDebounce);

                    // stop the scanner, save the code
                    this.value = code;
                    this.localValue = code;
                    this.stopScan();
                });

                this.scannerLoading = false;
                return true;
            },
            stopScan() {
                if ( this.barcodePicker) {
                    this.barcodePicker.destroy();
                    this.barcodePicker = false;
                }
                this.scanInProgress = false;
                this.scanInProgress = false;
                this.scannerLoading = false;
            }
        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">
.tr-scan-display-wrapper {
    top: 0;
    left: 0;
    background: rgba(255,255,255, 1);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    z-index: 200;

    .scan-display {
      position: relative;
      //  position: relative;
      //  height: 100%;
      //  width: 100%;
      //  min-height: 100vh;
      //  max-height: calc(100vw * 16 / 9);
        max-width: 100vw;
        z-index: 20;
    }

    .overlay-top {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 30px 20px;
        background: rgba(0,0,0,0.7);
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--global-background);
        font-weight: bold;
        z-index: 22;
        text-align: center;
        line-height: 1.25;
    }

    .overlay-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 20px 80px 20px;
        background: rgba(0,0,0,0.7);
        box-shadow: 0 0 15px 3px rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--global-background);
        font-weight: bold;
        z-index: 22;
    }

    .cancel-cta  {

        a {
            color: var(--global-background);
            text-decoration: underline;
            font-size: 14px;

            @media screen and (min-width: 1024px) {
                font-size: 16px;
            }
        }

    }

}

// iphone fix attempt I
[dir=rtl] .mobile-scan-icon {
  right: auto;
  left: 0;
  margin-left: 10px;
  position: absolute;
}

</style>

<style lang="scss">
.tr-scan-display-wrapper {
    z-index: 18;
    video, .drawingBuffer { // injected by Quagga, must be non-scoped
       //position: absolute;
       // left:0;
      //  top: 50%;
       // transform:  translateY(-50%);
       // max-width: 100%;
       // max-height: 100%;
    }

    .scan-display  {
      //  position: relative;
      .scandit.scandit-barcode-picker {
        border: 3px solid red;
        height: 100vh!important;
        max-height: 100vh!important;
      }

      .scandit-video.mirrored {
        height: 100vh!important;
        max-height: 100vh!important;
        object-fit: cover!important;
        top: 0;
        transform: none;
      }

      .scandit-camera-switcher, .scandit-torch-toggle{
        top: 100px;
      }
    }
    video {
     //   position: relative;
      //  border: 2px solid #ddd;
      //  box-shadow: 0 0 15px 3px rgba(0,0,0,0.5);
       // min-width: 100%;
       // background: transparent;

    }
}


</style>
