<template>
    <ul class="action-button-group uk-flex uk-flex-center uk-flex-stretch uk-gap uk-flex-wrap">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    data: function () {
        return {};
    }

}
</script>

<style scoped lang="scss">

</style>
